export const DataIcon = ({ ...props }) => {
  return (
    <svg
      width="74"
      height="52"
      viewBox="0 0 74 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M73.6198 46.228L67.8527 42.3994V9.47626C67.8527 7.13969 65.9309 5.2395 63.5676 5.2395H11.877L4.20095 0.143382C3.81672 -0.11153 3.28037 -0.0218514 3.01112 0.374992L0.145806 4.59551C-0.118443 4.98388 -0.0134574 5.51065 0.379345 5.77051L6.14782 9.59983V42.523C6.14782 44.8596 8.06969 46.7598 10.4329 46.7598H62.1235L69.7996 51.8559C70.1888 52.115 70.7237 52.0169 70.9894 51.6243L73.8555 47.4038C74.1183 47.0154 74.0126 46.4879 73.6205 46.228H73.6198ZM66.1358 22.1873H37.4063C34.4588 20.2306 31.9656 18.5761 28.4711 16.2558H66.1373L66.1358 22.1873ZM66.1351 23.882L66.1301 41.2555L39.9588 23.882H66.1351ZM63.5683 6.93491C64.986 6.93491 66.1394 8.07531 66.1394 9.47697L66.138 14.5611H25.9179L14.4302 6.93491H63.5676H63.5683ZM7.86972 16.2558H16.1728L25.108 22.1873H7.86829L7.86972 16.2558ZM7.86972 14.5611V10.7452L13.6189 14.5611H7.86972ZM10.4329 45.0658C9.01527 45.0658 7.86187 43.9254 7.86187 42.5237L7.86686 23.882H27.6598L59.5703 45.0658H10.4329ZM70.0438 49.9769C39.9874 30.0238 32.1813 24.8423 2.04768 4.83771L3.95813 2.0238L71.9543 47.163L70.0438 49.9769Z"
        fill="url(#paint0_linear_25_24107)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_25_24107"
          x1="37"
          y1="0"
          x2="37"
          y2="52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
