export const RiseIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_703_537)">
        <path
          d="M1.09375 56.0002C0.813749 56.0002 0.533749 55.893 0.320468 55.6797C-0.107189 55.2521 -0.107189 54.5597 0.320468 54.1331L12.7641 41.6896C13.1917 41.263 13.8841 41.263 14.3106 41.6896C14.7383 42.1172 14.7383 42.8095 14.3106 43.2361L1.86703 55.6797C1.65375 55.893 1.37375 56.0002 1.09375 56.0002Z"
          fill="url(#paint0_linear_703_537)"
        />
        <path
          d="M9.77157 54.7793C9.49157 54.7793 9.21157 54.6721 8.99829 54.4588C8.57064 54.0312 8.57064 53.3388 8.99829 52.9123L18.9449 42.9668C19.3714 42.5403 20.0649 42.5392 20.4914 42.9668C20.9191 43.3945 20.918 44.0868 20.4914 44.5134L10.5449 54.4588C10.3316 54.6721 10.0516 54.7793 9.77157 54.7793Z"
          fill="url(#paint1_linear_703_537)"
        />
        <path
          d="M21.8345 50.1724C21.5545 50.1724 21.2745 50.0652 21.0613 49.8519C20.6336 49.4243 20.6336 48.7319 21.0613 48.3054L25.8245 43.5421C26.2522 43.1155 26.9445 43.1155 27.3711 43.5421C27.7988 43.9697 27.7988 44.6621 27.3711 45.0887L22.6078 49.8519C22.3945 50.0652 22.1145 50.1724 21.8345 50.1724Z"
          fill="url(#paint2_linear_703_537)"
        />
        <path
          d="M2.31436 47.322C2.03436 47.322 1.75436 47.2148 1.54108 47.0015C1.11342 46.5739 1.11342 45.8815 1.54108 45.455L11.4865 35.5084C11.9142 35.0818 12.6065 35.0818 13.0331 35.5084C13.4597 35.9361 13.4608 36.6284 13.0331 37.055L3.08764 47.0015C2.87436 47.2148 2.59436 47.322 2.31436 47.322Z"
          fill="url(#paint3_linear_703_537)"
        />
        <path
          d="M6.92126 35.2592C6.64126 35.2592 6.36126 35.1521 6.14798 34.9388C5.72033 34.5111 5.72033 33.8188 6.14798 33.3922L10.9113 28.6289C11.3389 28.2024 12.0313 28.2013 12.4578 28.6289C12.8844 29.0566 12.8855 29.7489 12.4578 30.1755L7.69454 34.9388C7.48126 35.1521 7.20126 35.2592 6.92126 35.2592Z"
          fill="url(#paint4_linear_703_537)"
        />
        <path
          d="M29.1342 38.8028C28.8542 38.8028 28.5742 38.6957 28.3609 38.4824L17.5186 27.64C17.0909 27.2124 17.0909 26.52 17.5186 26.0935L21.8859 21.7261C22.3136 21.2985 23.0059 21.2985 23.4325 21.7261C23.8591 22.1538 23.8602 22.8461 23.4325 23.2727L19.8395 26.8657L29.1353 36.1614L44.0562 21.2405C44.2619 21.0349 44.5397 20.92 44.8295 20.92C45.1194 20.92 45.3983 21.0349 45.6028 21.2405L49.5392 25.1769L53.5587 2.44331L30.8251 6.46284L34.7616 10.3992C35.1892 10.8269 35.1892 11.5192 34.7616 11.9458L30.3942 16.3132C29.9666 16.7408 29.2742 16.7408 28.8477 16.3132C28.42 15.8855 28.42 15.1932 28.8477 14.7666L32.4417 11.1725L27.7944 6.52409C27.5056 6.23534 27.4017 5.81097 27.5242 5.42159C27.6467 5.03222 27.9759 4.74456 28.3773 4.67347L54.7159 0.016278C55.0681 -0.0460657 55.428 0.0676843 55.6795 0.320341C55.9322 0.572997 56.0448 0.932841 55.9836 1.28393L51.3264 27.6258C51.2553 28.0272 50.9666 28.3564 50.5783 28.4789C50.1889 28.6014 49.7645 28.4975 49.4758 28.2088L44.8284 23.5614L29.9075 38.4824C29.6942 38.6957 29.4142 38.8028 29.1342 38.8028Z"
          fill="url(#paint5_linear_703_537)"
        />
        <path
          d="M26.1384 20.1128C25.5347 20.1128 25.0447 19.6228 25.0447 19.019C25.0447 18.4153 25.5336 17.9253 26.1384 17.9253C26.7422 17.9253 27.2322 18.4153 27.2322 19.019C27.2322 19.6228 26.7422 20.1128 26.1384 20.1128Z"
          fill="url(#paint6_linear_703_537)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_703_537"
          x1="7.31555"
          y1="41.3696"
          x2="7.31555"
          y2="56.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_537"
          x1="14.7447"
          y1="42.6465"
          x2="14.7447"
          y2="54.7793"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_703_537"
          x1="24.2162"
          y1="43.2222"
          x2="24.2162"
          y2="50.1724"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_703_537"
          x1="7.28689"
          y1="35.1885"
          x2="7.28689"
          y2="47.322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_703_537"
          x1="9.3027"
          y1="28.3086"
          x2="9.3027"
          y2="35.2592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_703_537"
          x1="36.5989"
          y1="-0.000488281"
          x2="36.5989"
          y2="38.8028"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_703_537"
          x1="26.1384"
          y1="17.9253"
          x2="26.1384"
          y2="20.1128"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <clipPath id="clip0_703_537">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
