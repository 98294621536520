export const InvestShadow = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 1920 2001"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_703_2)">
        <rect
          x="813.254"
          y="1037.79"
          width="478.593"
          height="443.496"
          transform="rotate(105 813.254 1037.79)"
          fill="url(#paint0_linear_703_2)"
          fillOpacity="0.45"
        />
      </g>
      <g filter="url(#filter1_f_703_2)">
        <rect
          x="1622.19"
          y="1042.67"
          width="465.539"
          height="431.4"
          transform="rotate(105 1622.19 1042.67)"
          fill="url(#paint1_linear_703_2)"
          fillOpacity="0.45"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_703_2"
          x="-239"
          y="423"
          width="1552.25"
          height="1577.07"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_703_2"
          />
        </filter>
        <filter
          id="filter1_f_703_2"
          x="585"
          y="431.019"
          width="1537.19"
          height="1561.33"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_703_2"
          />
        </filter>
        <linearGradient
          id="paint0_linear_703_2"
          x1="984.18"
          y1="930.271"
          x2="1366.95"
          y2="988.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_2"
          x1="1788.45"
          y1="938.092"
          x2="2160.79"
          y2="994.854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <clipPath id="clip0_703_2">
          <rect
            width="1745.87"
            height="839.481"
            fill="white"
            transform="translate(343 558.645) rotate(-18.6618)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
