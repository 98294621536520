export const RocketIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_703_796)">
        <path
          d="M50.9189 1.81112C50.8264 1.45287 50.5471 1.17357 50.1889 1.08114C41.8937 -1.05802 33.1705 -0.000740378 25.6263 4.05739C23.9684 4.94923 22.4005 5.96843 20.933 7.10188C16.734 5.60423 12.0633 6.63771 8.88317 9.81788L2.5272 16.1735C2.13087 16.5702 2.13087 17.2133 2.5272 17.61L9.59927 24.6817C9.59927 24.6821 9.59967 24.6821 9.59967 24.6825L10.5117 25.5942L9.67266 31.7593C9.62982 32.0739 9.73614 32.3901 9.96069 32.6147L10.6994 33.3534L4.63184 39.4574C4.2363 39.8553 4.23829 40.4984 4.6362 40.8936C5.03412 41.2891 5.67722 41.2871 6.07236 40.8896L12.1355 34.7895L17.2109 39.8653L8.65902 48.3794C8.2615 48.7754 8.25992 49.4185 8.65545 49.816C8.85421 50.0151 9.11486 50.1151 9.37551 50.1151C9.63458 50.1151 9.89364 50.0163 10.092 49.8192L18.6474 41.3014L19.3854 42.0397C19.5774 42.2313 19.836 42.3369 20.1034 42.3369C20.1491 42.3369 20.1947 42.3341 20.2407 42.3277L26.4059 41.4887L27.3179 42.4007C27.3179 42.4007 27.3179 42.4011 27.3183 42.4011L34.39 49.4732C34.5884 49.6716 34.8482 49.7704 35.1081 49.7704C35.3683 49.7704 35.6282 49.6716 35.8265 49.4732L42.1821 43.1172C45.3623 39.9371 46.3962 35.2664 44.8981 31.0674C46.0316 29.5999 47.0508 28.0317 47.9426 26.3741C52.0011 18.8296 53.058 10.1063 50.9189 1.81112ZM9.89483 22.1049L4.68183 16.8919L10.3193 11.2544C12.6398 8.93357 15.9231 7.99888 19.0473 8.67094C14.9777 12.3133 11.8309 16.9038 9.89483 22.1049ZM20.4656 40.2469L11.7531 31.5344L12.3161 27.3985L24.6015 39.6839L20.4656 40.2469ZM40.746 41.6807L35.1081 47.3182L29.8955 42.1052C35.0966 40.1691 39.6867 37.0223 43.3291 32.9527C44.0011 36.0769 43.0664 39.3598 40.746 41.6807ZM28.4268 40.4742L28.3058 40.5159L11.4841 23.6942L11.5258 23.5732C16.8411 8.12425 33.2597 -0.862433 49.0967 2.90331C52.862 18.7399 43.8762 35.1589 28.4268 40.4742Z"
          fill="url(#paint0_linear_703_796)"
        />
        <path
          d="M39.5919 11.1641L26.2618 14.7358C25.9115 14.8298 25.6378 15.1036 25.5438 15.4543C25.4497 15.8046 25.5501 16.1787 25.8064 16.4354L27.7869 18.4155L23.7962 22.4057C23.3998 22.8025 23.3998 23.4456 23.7962 23.8423C24.1929 24.2386 24.836 24.2386 25.2327 23.8423L29.9411 19.1335C30.3378 18.7372 30.3378 18.0941 29.9411 17.6974L28.4867 16.243L38.4188 13.5817L35.7575 23.5134L34.3031 22.059C34.1127 21.8686 33.8544 21.7619 33.585 21.7619C33.3157 21.7619 33.057 21.8686 32.8666 22.059L24.7622 30.1638C24.3655 30.5605 24.3655 31.2036 24.7622 31.6003C24.9606 31.7987 25.2204 31.8975 25.4803 31.8975C25.7401 31.8975 26 31.7987 26.1984 31.6003L33.5846 24.2136L35.5647 26.1937C35.8214 26.4504 36.1955 26.5504 36.5458 26.4567C36.8965 26.3627 37.1703 26.089 37.2639 25.7387L40.8356 12.4082C40.9297 12.0579 40.8297 11.6838 40.573 11.4271C40.3163 11.1704 39.9422 11.07 39.5919 11.1641Z"
          fill="url(#paint1_linear_703_796)"
        />
        <path
          d="M9.74883 42.2512C9.3525 41.8544 8.7094 41.8544 8.31268 42.2512L0.297584 50.2663C-0.0991438 50.6626 -0.0991438 51.3057 0.297584 51.7024C0.495948 51.9008 0.755805 52 1.01566 52C1.27552 52 1.53577 51.9008 1.73374 51.7024L9.74883 43.6873C10.1456 43.291 10.1456 42.6479 9.74883 42.2512Z"
          fill="url(#paint2_linear_703_796)"
        />
        <path
          d="M2.55655 43.9844C2.82355 43.9844 3.08539 43.8769 3.27463 43.6868C3.46347 43.498 3.57218 43.2369 3.57218 42.9687C3.57218 42.7018 3.46347 42.4399 3.27463 42.2511C3.08539 42.0618 2.82355 41.9531 2.55655 41.9531C2.28837 41.9531 2.02732 42.0618 1.83848 42.2511C1.64964 42.4399 1.54093 42.7018 1.54093 42.9687C1.54093 43.2369 1.64964 43.498 1.83848 43.6868C2.02732 43.8769 2.28916 43.9844 2.55655 43.9844Z"
          fill="url(#paint3_linear_703_796)"
        />
        <path
          d="M20.4577 25.7439L20.449 25.7515C20.0312 26.1264 19.9967 26.7687 20.3716 27.186C20.572 27.4094 20.8493 27.5233 21.1278 27.5233C21.3694 27.5233 21.6118 27.4376 21.8058 27.263L21.8145 27.2555C22.2319 26.8806 22.2668 26.2387 21.8919 25.8213C21.517 25.4035 20.8751 25.369 20.4577 25.7439Z"
          fill="url(#paint4_linear_703_796)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_703_796"
          x1="-1.91756"
          y1="-28.0948"
          x2="59.7401"
          y2="-21.4239"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_796"
          x1="22.0513"
          y1="-0.513302"
          x2="43.6445"
          y2="1.45437"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_703_796"
          x1="-0.837157"
          y1="36.3216"
          x2="11.6067"
          y2="37.6772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_703_796"
          x1="1.37166"
          y1="40.8144"
          x2="3.88766"
          y2="41.0885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_703_796"
          x1="19.9416"
          y1="24.3406"
          x2="22.4686"
          y2="24.616"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <clipPath id="clip0_703_796">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
