export const FeatureShadow = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 1920 2629"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_60_25)">
        <rect
          x="1473.14"
          y="709.126"
          width="871.943"
          height="808"
          transform="rotate(105 1473.14 709.126)"
          fill="url(#paint0_linear_60_25)"
          fillOpacity="0.45"
        />
      </g>
      <g filter="url(#filter1_f_60_25)">
        <rect
          x="2207.14"
          y="1286.13"
          width="871.943"
          height="808"
          transform="rotate(105 2207.14 1286.13)"
          fill="url(#paint1_linear_60_25)"
          fillOpacity="0.45"
        />
      </g>
      <g filter="url(#filter2_f_60_25)">
        <path
          d="M615.963 1349.41L501.131 1777.97L104 1671.56L218.832 1243L615.963 1349.41Z"
          fill="url(#paint2_linear_60_25)"
          fillOpacity="0.45"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_60_25"
          x="-33.0003"
          y="0"
          width="2006.14"
          height="2051.36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_60_25"
          />
        </filter>
        <filter
          id="filter1_f_60_25"
          x="701"
          y="577"
          width="2006.14"
          height="2051.36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_60_25"
          />
        </filter>
        <filter
          id="filter2_f_60_25"
          x="-296"
          y="843"
          width="1311.96"
          height="1334.97"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="200"
            result="effect1_foregroundBlur_60_25"
          />
        </filter>
        <linearGradient
          id="paint0_linear_60_25"
          x1="1784.55"
          y1="513.247"
          x2="2481.92"
          y2="619.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_60_25"
          x1="2518.55"
          y1="1090.25"
          x2="3215.92"
          y2="1196.56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_60_25"
          x1="671.226"
          y1="1528.26"
          x2="527.132"
          y2="1857.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
      </defs>
    </svg>
  );
};
