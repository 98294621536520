import cx from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: 'normal' | 'outline';
}

const classes = {
  root: 'text-white leading-none py-5 rounded-full',
  normal: 'px-8 text-sm sm:text-base font-medium',
  outline:
    'px-8 text-sm sm:text-base font-bold border border-border-white bg-transparent'
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { className, variant, children, ...rest } = props;
    return (
      <button
        className={cx(classes.root, className, {
          [classes.outline]: variant === 'outline',
          [classes.normal]: variant === 'normal'
        })}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
