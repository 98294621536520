export const PrizeIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 49 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.87604 20.8393C7.87604 20.1435 8.44013 19.5792 9.13615 19.5792C9.83217 19.5792 10.3963 20.1433 10.3963 20.8393C10.3963 21.5354 9.83217 22.0995 9.13615 22.0995C8.44013 22.0995 7.87604 21.5352 7.87604 20.8393ZM46.7292 37.6891L32.6803 36.4127L28.775 47.3683H35.7194C36.4152 47.3683 36.9794 47.9325 36.9794 48.6283V52.4082H39.4994C40.1951 52.4082 40.7594 52.9725 40.7594 53.6682V61.4802H41.3893C42.0851 61.4802 42.6493 62.0444 42.6493 62.7402C42.6493 63.4359 42.0851 64.0001 41.3893 64.0001H7.36965C6.67388 64.0001 6.10966 63.4359 6.10966 62.7402C6.10966 62.0444 6.67388 61.4802 7.36965 61.4802H7.99964V53.6682C7.99964 52.9725 8.56386 52.4082 9.25963 52.4082H11.7796V48.6283C11.7796 47.9325 12.3438 47.3683 13.0396 47.3683H20.2582L15.46 32.6428L0.794898 26.8771C-0.00393524 26.6435 -0.409777 25.3265 0.59456 24.6318L3.64877 22.7456C3.65003 22.7456 3.65142 22.7456 3.65142 22.7442C5.05479 21.8798 6.3764 24.0256 4.97302 24.89L4.06092 25.4532L16.9355 30.5147C17.2858 30.6533 17.5554 30.9379 17.6726 31.2971L22.9093 47.3683H26.1001L30.634 34.6461C30.8256 34.1093 31.3661 33.7628 31.9355 33.8145L44.4737 34.9535L37.3762 23.2356C37.1771 22.9067 37.1407 22.496 37.2766 22.1357L42.2449 9.0519L29.2328 12.677C28.8448 12.7841 28.4126 12.6947 28.1002 12.4401L17.4496 3.77632L16.6255 17.0679C16.6003 17.4762 16.3786 17.8479 16.0308 18.0622L14.622 18.9327C13.1933 19.815 11.8691 17.6705 13.2978 16.7882L14.1508 16.2616L15.0858 1.17445C15.1612 0.244576 16.2952 -0.38945 17.1383 0.274816L29.185 10.0737L43.9697 5.95611C45.0717 5.71948 45.8521 6.69484 45.4854 7.6179L39.8508 22.4555L47.921 35.7824C48.5103 36.5934 47.7787 37.7639 46.7292 37.6891ZM15.8116 54.9282H10.5196V61.4802H15.8116V54.9282ZM30.4274 54.9282H18.3315V61.4802H30.4274V54.9282ZM32.9474 54.9282V61.4802H38.2394V54.9282H32.9474ZM34.4594 49.8883H14.2996V52.4082H34.4594V49.8883Z"
        fill="url(#paint0_linear_703_157)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_703_157"
          x1="-4.01092"
          y1="-35.8788"
          x2="55.9118"
          y2="-30.9694"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
      </defs>
    </svg>
  );
};
