export const ContactShadow = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 1920 2375"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.7" filter="url(#filter0_f_703_42)">
        <rect
          x="1621.12"
          y="773.438"
          width="1140.09"
          height="1056.49"
          transform="rotate(105 1621.12 773.438)"
          fill="url(#paint0_linear_703_42)"
          fillOpacity="0.45"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_703_42"
          x="-194.445"
          y="0"
          width="2315.56"
          height="2374.68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_703_42"
          />
        </filter>
        <linearGradient
          id="paint0_linear_703_42"
          x1="2028.3"
          y1="517.321"
          x2="2940.13"
          y2="656.329"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
      </defs>
    </svg>
  );
};
