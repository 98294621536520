export const TradeIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.2688 45.7938C39.7375 45.7938 39.3125 45.3688 39.3125 44.8376V37.9313C39.3125 37.4001 39.7375 36.9751 40.2688 36.9751C40.8 36.9751 41.225 37.4001 41.225 37.9313V44.8376C41.225 45.3688 40.8 45.7938 40.2688 45.7938Z"
        fill="url(#paint0_linear_703_744)"
      />
      <path
        d="M36.8688 42.8188C36.3375 42.8188 35.9125 42.3939 35.9125 41.8626V37.9313C35.9125 37.4001 36.3375 36.9751 36.8688 36.9751C37.4 36.9751 37.825 37.4001 37.825 37.9313V41.8626C37.825 42.3939 37.4 42.8188 36.8688 42.8188Z"
        fill="url(#paint1_linear_703_744)"
      />
      <path
        d="M38.5687 39.1003C35.1687 39.1003 32.3 36.6565 31.7687 33.3628C31.6625 32.8315 32.0875 32.4065 32.5125 32.3003C33.0437 32.194 33.4687 32.619 33.575 33.044C33.8937 35.4878 36.0187 37.294 38.4625 37.294C41.225 37.294 43.4563 35.0628 43.4563 32.3003C43.4563 31.0253 42.925 30.069 42.5 29.4315C42.1812 29.0065 42.2875 28.4753 42.7125 28.1565C43.1375 27.8378 43.6688 27.944 43.9875 28.369C44.8375 29.5378 45.1562 30.919 45.1562 32.3003C45.475 36.019 42.3937 39.1003 38.5687 39.1003ZM38.4625 34.319C36.6562 34.319 35.275 32.9378 35.275 31.1315C35.275 30.919 35.275 30.494 35.4875 30.069C35.7 29.644 36.2312 29.4315 36.7625 29.644C37.1875 29.8565 37.4 30.3878 37.1875 30.919V31.1315C37.1875 31.8753 37.7187 32.4065 38.4625 32.4065C39.2062 32.4065 39.7375 31.8753 39.7375 31.1315C39.7375 30.7065 39.6312 30.494 39.6312 30.3878C39.3125 30.069 39.3125 29.4315 39.6312 29.1128C39.95 28.794 40.5875 28.794 40.9062 29.1128C41.3312 29.5378 41.65 30.2815 41.65 31.1315C41.65 32.9378 40.2687 34.319 38.4625 34.319Z"
        fill="url(#paint2_linear_703_744)"
      />
      <path
        d="M38.5687 38.994C37.825 38.994 37.1875 38.8878 36.6563 38.7815C33.8938 38.0378 31.9813 35.9128 31.6625 33.2565C31.5563 32.7253 31.9813 32.3003 32.5125 32.194C33.0438 32.0878 33.4688 32.5128 33.575 33.044C33.7875 34.9565 35.1688 36.5503 37.1875 37.0815C37.6125 37.1878 38.0375 37.294 38.675 37.294C39.2063 37.294 39.7375 37.1878 40.1625 37.0815C42.1812 36.444 43.6687 34.5315 43.6687 32.3003C43.6687 31.0253 43.1375 30.069 42.7125 29.4315C42.3938 29.0065 42.5 28.4753 42.925 28.1565C43.35 27.8378 43.8812 27.944 44.2 28.369C45.05 29.5378 45.3688 30.919 45.3688 32.3003C45.3688 35.3815 43.35 38.0378 40.4812 38.8878C39.95 38.8878 39.3125 38.994 38.5687 38.994Z"
        fill="url(#paint3_linear_703_744)"
      />
      <path
        d="M64.9188 18.4877H61.0938C58.8625 18.4877 57.1625 16.6814 57.1625 14.5564V4.0377C57.1625 1.80645 58.9688 0.106445 61.0938 0.106445H64.9188C65.45 0.106445 65.875 0.531445 65.875 1.0627V17.5314C65.875 18.0627 65.45 18.4877 64.9188 18.4877ZM61.0938 2.01895C60.0313 2.01895 59.075 2.86895 59.075 4.0377V14.5564C59.075 15.6189 59.925 16.5752 61.0938 16.5752H63.9625V2.01895H61.0938Z"
        fill="url(#paint4_linear_703_744)"
      />
      <path
        d="M29.2188 30.9189C29.1125 30.9189 29.1125 30.9189 29.0063 30.9189C28.3688 30.8126 27.9438 30.4939 27.625 30.0689C27.3063 29.6439 27.2 29.1126 27.3063 28.6876L29.9625 17.6376C32.1938 8.7126 40.1625 2.44385 49.4063 2.44385H58.0125C58.5437 2.44385 58.9688 2.86885 58.9688 3.4001V15.1938C58.9688 15.6188 58.65 16.0438 58.225 16.1501C56.3125 16.5751 54.4 17.4251 52.7 18.5938C48.5562 23.1626 44.8375 25.6064 41.1188 26.2439C40.2688 26.4564 39.525 26.6689 38.675 27.0939C38.25 27.3064 37.7188 27.5189 37.1875 27.5189C36.2313 27.5189 35.275 26.9876 34.6375 26.1376C34.425 26.6689 34.2125 27.2001 34 27.6251C33.2563 29.6439 31.3438 30.9189 29.2188 30.9189ZM49.5125 4.35635C41.225 4.35635 34 9.9876 31.9813 18.0626L29.325 28.9001C30.6 28.7939 31.7688 28.0501 32.3 26.7751C33.15 24.8626 34.1063 22.5251 34.6375 21.2501C35.3813 19.2313 36.4438 17.5313 37.825 16.1501C38.8875 15.0876 40.9063 15.1938 41.9688 16.3626C42.5 16.8938 42.7125 17.6376 42.6063 18.4876C42.6063 19.5501 41.7563 20.1876 41.4375 20.4001L36.6563 23.5876L36.3375 23.9064C36.0188 24.3314 36.125 24.7564 36.2313 24.9689C36.55 25.6064 37.1875 25.8189 37.825 25.5001C38.8875 24.9689 39.7375 24.6501 40.8 24.4376C44.0938 23.8001 47.3875 21.5688 51.3188 17.3188L51.425 17.2126C53.125 15.9376 55.0375 15.0876 56.95 14.5563V4.35635H49.5125ZM39.8438 17.4251C39.6313 17.4251 39.4188 17.5313 39.3125 17.6376C38.3563 18.5938 37.5063 19.7626 36.975 21.0376L40.375 18.8064C40.6938 18.5938 40.6938 18.4876 40.6938 18.4876V18.3813C40.6938 18.1688 40.6938 17.9563 40.4813 17.8501C40.375 17.5313 40.0563 17.4251 39.8438 17.4251Z"
        fill="url(#paint5_linear_703_744)"
      />
      <path
        d="M36.4437 51.1065C36.2312 51.1065 35.9125 51.0003 35.7 50.7878C35.275 50.3628 35.275 49.7253 35.7 49.4065C35.9125 49.194 36.0188 48.769 36.0188 48.344C36.0188 47.1753 35.1687 46.3253 34.1062 46.3253H29.4312C27.9438 46.3253 26.6688 45.794 25.6063 44.7315C23.375 42.6065 20.0813 42.2878 17.5313 43.8815C15.8313 44.944 14.45 46.1128 13.2813 47.494C12.9625 47.919 12.325 48.0253 11.9 47.7065C11.475 47.3878 11.3688 46.7503 11.6875 46.3253C12.9625 44.7315 14.5563 43.3503 16.4688 42.2878C19.7625 40.1628 24.1188 40.5878 26.9875 43.3503C27.625 43.9878 28.475 44.3065 29.4312 44.3065H34.1062C36.2312 44.3065 37.9312 46.0065 37.9312 48.1315C37.9312 49.3003 37.6125 50.1503 36.975 50.7878C36.975 51.0003 36.6562 51.1065 36.4437 51.1065Z"
        fill="url(#paint6_linear_703_744)"
      />
      <path
        d="M21.4625 59.5001C21.0375 59.5001 20.6125 59.2876 20.5063 58.8626C20.2938 58.3313 20.6125 57.8001 21.1438 57.5876C21.5688 57.4813 21.9938 57.4813 22.3125 57.4813L33.4688 57.1626C36.0188 57.0563 38.3563 56.4188 40.6938 55.3563C44.5188 53.4438 47.9188 51.2126 50.8938 48.7688C51.1063 48.6626 51.2125 48.4501 51.2125 48.2376C51.2125 48.1313 51.2125 48.0251 50.8938 47.6001C49.725 46.6438 48.1313 46.5376 46.9625 47.2813C44.0938 49.0876 40.5875 50.1501 37.1875 51.1063C36.4438 51.9563 35.3813 52.3813 34.3188 52.3813H28.6875C28.1563 52.3813 27.7313 51.9563 27.7313 51.4251C27.7313 50.8938 28.1563 50.4688 28.6875 50.4688H34.3188C34.9563 50.4688 35.5938 50.0438 35.9125 49.6188C36.0188 49.5126 36.2313 49.3001 36.4438 49.3001C39.7375 48.4501 43.2438 47.3876 45.9 45.6876C47.8125 44.4126 50.4688 44.6251 52.275 46.2188C52.3813 46.2188 52.3813 46.3251 52.3813 46.3251C52.9125 46.9626 53.125 47.6001 53.125 48.2376C53.125 49.0876 52.7 49.8313 52.0625 50.2563C48.9813 52.8063 45.475 55.0376 41.5438 57.0563C38.9938 58.3313 36.2313 58.9688 33.4688 59.0751L22.3125 59.3938C21.9938 59.3938 21.8875 59.3938 21.7813 59.5001C21.675 59.5001 21.5688 59.5001 21.4625 59.5001Z"
        fill="url(#paint7_linear_703_744)"
      />
      <path
        d="M14.2375 67.8938H14.1313C13.9188 67.8938 13.6 67.6813 13.4938 67.4688L2.3375 52.5938C2.01875 52.1688 2.125 51.5313 2.55 51.2126L6.375 48.3438C7.0125 47.8126 7.8625 47.6001 8.7125 47.6001C9.9875 47.6001 11.1563 48.2376 11.7938 49.3001L19.4438 59.5001C20.0813 60.2438 20.4 61.3063 20.2938 62.2626C20.1875 63.3251 19.6563 64.2813 18.7 64.9188L14.875 67.7876C14.6625 67.8938 14.45 67.8938 14.2375 67.8938ZM4.4625 52.2751L14.45 65.5563L17.5313 63.3251C17.9563 63.0063 18.275 62.5813 18.275 62.0501C18.275 61.7313 18.275 61.2001 17.85 60.7751L10.2 50.5751C9.5625 49.6188 8.39375 49.5126 7.54375 50.1501L4.4625 52.2751Z"
        fill="url(#paint8_linear_703_744)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_703_744"
          x1="39.1531"
          y1="32.0313"
          x2="41.5488"
          y2="32.0879"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_744"
          x1="35.7531"
          y1="33.6991"
          x2="38.1471"
          y2="33.7844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_703_744"
          x1="30.6333"
          y1="21.7322"
          x2="47.1759"
          y2="23.9064"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_703_744"
          x1="30.5026"
          y1="21.7917"
          x2="47.3912"
          y2="24.0822"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_703_744"
          x1="56.4365"
          y1="-10.1982"
          x2="67.3272"
          y2="-9.63582"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_703_744"
          x1="24.6245"
          y1="-13.5194"
          x2="63.7826"
          y2="-8.76993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_703_744"
          x1="9.29974"
          y1="35.2466"
          x2="39.9633"
          y2="43.9341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_703_744"
          x1="17.7159"
          y1="36.6517"
          x2="56.3941"
          y2="46.0588"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_703_744"
          x1="0.638691"
          y1="36.2233"
          x2="23.1873"
          y2="38.4217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
      </defs>
    </svg>
  );
};
