export const LockIcon = ({ ...props }) => {
  return (
    <svg
      width="59"
      height="52"
      viewBox="0 0 59 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.6351 20.2004H28.7086V12.8407C28.7086 5.76013 22.2696 0 14.3558 0C6.43958 0 0 5.76025 0 12.8407V19.902C0 20.103 0.163207 20.2662 0.364196 20.2662H3.67611C3.8771 20.2662 4.04031 20.103 4.04031 19.902V12.8407C4.04031 7.98826 8.66788 4.04054 14.3555 4.04054C20.042 4.04054 24.6684 7.98843 24.6684 12.8407V20.2004H20.5733C20.3723 20.2004 20.2091 20.3636 20.2091 20.5646L20.2093 51.3663C20.2093 51.5673 20.3725 51.7305 20.5735 51.7305H58.6358C58.8368 51.7305 59 51.5673 59 51.3663V20.5646C59 20.3634 58.8368 20.2004 58.6358 20.2004H58.6351ZM14.3552 3.31157C8.2658 3.31157 3.31157 7.58653 3.31157 12.8401V19.5373L0.72781 19.5375V12.8403C0.72781 6.16185 6.84107 0.728037 14.3552 0.728037C21.8681 0.728037 27.9796 6.16115 27.9796 12.8403V20.2H25.3961L25.3959 12.8403C25.3959 7.58635 20.4428 3.31121 14.3552 3.31121L14.3552 3.31157ZM58.2709 51.0015H20.9369V20.9282H58.2709V51.0015Z"
        fill="url(#paint0_linear_25_24088)"
      />
      <path
        d="M39.6041 43.285C43.6401 43.285 46.9242 40.0007 46.9242 35.965C46.9242 31.9293 43.6398 28.6455 39.6041 28.6455C35.5681 28.6455 32.2847 31.9292 32.2847 35.965C32.2847 40.0007 35.5681 43.285 39.6041 43.285ZM39.6041 29.3733C43.2388 29.3733 46.1958 32.3306 46.1958 35.9644C46.1958 39.5991 43.2385 42.5561 39.6041 42.5561C35.9702 42.5561 33.0131 39.5988 33.0131 35.9644C33.0131 32.3304 35.9703 29.3733 39.6041 29.3733Z"
        fill="url(#paint1_linear_25_24088)"
      />
      <path
        d="M39.6041 39.5297C41.5701 39.5297 43.1696 37.9309 43.1696 35.9642C43.1696 33.9989 41.5708 32.3994 39.6041 32.3994C37.6388 32.3994 36.0393 33.9983 36.0393 35.9642C36.0393 37.9302 37.6388 39.5297 39.6041 39.5297ZM39.6041 33.1286C41.1679 33.1286 42.4412 34.401 42.4412 35.965C42.4412 37.5288 41.1688 38.8022 39.6041 38.8022C38.041 38.8022 36.7677 37.5297 36.7677 35.965C36.7677 34.4013 38.0408 33.1286 39.6041 33.1286Z"
        fill="url(#paint2_linear_25_24088)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_25_24088"
          x1="29.5"
          y1="0"
          x2="29.5"
          y2="51.7305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_25_24088"
          x1="39.6044"
          y1="28.6455"
          x2="39.6044"
          y2="43.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_25_24088"
          x1="39.6045"
          y1="32.3994"
          x2="39.6045"
          y2="39.5297"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
