/* eslint-disable react/no-unknown-property */
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import Script from 'next/script';
import { ToastContainer } from 'react-toastify';
import '@/styles/globals.scss';
import { ThemeProvider } from 'next-themes';
import Layout from '@/layouts/main';
import { Provider } from 'react-redux';
import type { AppProps } from 'next/app';
import { store } from '../redux/store';
import { useRouter } from 'next/router';
import { MetaMaskProvider } from 'metamask-react';
import Meta from '@/components/meta';
import UserContext from '@/components/userContext';
import { useRef } from 'react';
import typography from '@/theme/typography';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const pid = router.route;
  const scrollRef = useRef({
    scrollPos: 0
  });

  const client = new ApolloClient({
    uri:
      process.env.NODE_ENV === 'development'
        ? '/graphql'
        : 'https://api.nestflo.ai/graphql',
    cache: new InMemoryCache()
  });

  return (
    <>
      <Meta title="Nestflo Home" />
      <style jsx global>
        {`
          :root {
            --font-poppins: ${typography.fontFamily};
          }
        `}
      </style>
      {/* <Script src="https://www.googletagmanager.com/gtag/js?id=G-N52NQZK5D0" />
      <Script src="https://salesiq.zoho.com/widget" id="zsiqscript" defer />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-N52NQZK5D0');
        `}
      </Script>

      <Script id="zoho-desktop">{`
        var $zoho = $zoho || {};
    $zoho.salesiq = $zoho.salesiq || {
        widgetcode: "siqd690ae45c76c1b9b2a1204eebbaf642a2f4ff00b190dfbbd285f565f8b71fb6a",
        values: {},
        ready: function() {}
    };
      `}</Script>
      <Script id="gdp-cookie-notice">{`
          const handleScriptLoad = () => {
            try {
              window.gdprCookieNotice({
                locale: 'en', // This is the default value
                timeout: 500, // Time until the cookie bar appears
                expiration: 30, // This is the default value, in days
                domain: 'nestflo.ai', // If you run the same cookie notice on all subdomains, define the main domain starting with a .
                implicit: false, // Accept cookies on scroll
                statement: 'https://nestflo.ai/assets/documents/cookie-policy.pdf', // Link to your cookie statement page
                performance: ['JSESSIONID'], // Cookies in the performance category.
                analytics: ['ga'], // Cookies in the analytics category.
                marketing: ['SSID'], // Cookies in the marketing category.
              });
            } catch (error) {
              console.log(error);
            }
          };
          const script = document.createElement('script');
          script.src = '/assets/GDPRCookie/script.js';
          script.onload = handleScriptLoad;
          document.body.appendChild(script);
        `}</Script> */}
      <ApolloProvider client={client}>
        <Provider store={store}>
          <ThemeProvider enableSystem={true} attribute="class">
            <MetaMaskProvider>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {['/login', '/[code]'].includes(pid) ? (
                  <Component {...pageProps} />
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                )}
                <ToastContainer
                  position="top-center"
                  theme="dark"
                  hideProgressBar
                  autoClose={3000}
                />
              </UserContext.Provider>
            </MetaMaskProvider>
          </ThemeProvider>
        </Provider>
      </ApolloProvider>
      <div id="zsiqwidget"></div>
    </>
  );
}

export default MyApp;
