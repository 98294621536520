import React from 'react';
import map from 'lodash/map';
import Image from 'next/image';
import Logo from '~/logo/logo_full.png';
import Button from '@/components/button';
import Line from '@/components/line';
interface LinkProps {
  title: string;
  href: string;
}

const links: LinkProps[] = [
  {
    title: 'Website T&C',
    href: '/assets/documents/terms-conditions.pdf'
  },
  {
    title: 'Privacy Policy',
    href: '/assets/documents/privacy-policy.pdf'
  },
  {
    title: 'Cookie policy',
    href: '/assets/documents/cookie-policy.pdf'
  }
];
const Footer: React.FC = () => {
  return (
    <div className="mt-20 lg:mt-40">
      <Line variant="medium" className="md:z-10" />
      <section className="relative overflow-hidden bg-gradient-to-t from-blue to-purple py-12 shadow-purple lg:pb-40 lg:pt-32">
        <picture className="absolute inset-x-0 bottom-0">
          <img
            src="/assets/background/bg_footer.png"
            alt="dark"
            className="h-auto w-full opacity-80"
          />
        </picture>
        <div className="container">
          <div className="relative z-10 mx-auto w-full max-w-[1467px] overflow-hidden rounded-[36px] border border-sky/50 bg-[#150649] p-4 lg:rounded-[40px] lg:p-16">
            <picture className="absolute bottom-0 right-0 -z-20">
              <img
                src="/assets/background/bg_footer_2_op.png"
                alt="dark"
                className="h-auto w-full opacity-30"
              />
            </picture>
            <div className="md:flex md:items-center md:justify-between">
              <Image
                src={Logo}
                alt="Nestflo"
                className="w-[146px] lg:w-[200px]"
              />
              <ul className="mt-6 justify-center space-y-4 pl-3 md:mt-0 lg:flex lg:space-x-12 lg:space-y-0 lg:pl-0">
                {map(links, (link, index) => (
                  <li key={index}>
                    <a
                      href={link.href}
                      target="_blank"
                      className="hover:text-gray-300 text-sm font-medium uppercase tracking-[0.25em] text-white lg:text-base"
                      rel="noreferrer"
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-16">
              <h3 className="hidden text-4xl font-medium">Sign up</h3>
              <form className="mt-6 hidden">
                {/* <form className="mt-6 hidden sm:flex sm:max-w-2xl"> */}
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <div className="input-wrapper">
                  <input
                    type="email"
                    name="email-address"
                    id="email-address"
                    autoComplete="email"
                    required
                    className="w-full min-w-0 appearance-none rounded-3xl border-0 bg-darker/20 px-8 py-1.5 text-base  text-white placeholder:text-[#6523EF]"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="-ml-24 sm:shrink-0">
                  <Button className="bg-gradient-to-r from-blue via-blueLight to-purpleLight px-16">
                    Submit
                  </Button>
                </div>
              </form>
              <div className="mt-12 space-y-8 lg:w-1/2">
                <p className="font-light text-[#6523EF]">
                  Copyright 2024 Kunpro Ltd. All Rights Reserved
                </p>
                <p className="text-xs font-light text-[#6523EF]">
                  Kunpro ltd including trade under the brand Nestflo is
                  registered in England and Wales under registration number
                  13908049. The registered address is Unit 111, Filwood Green
                  Business Park, 1 Filwood Park Lane, Bristol, England, BS4 1ET,
                  United Kingdom
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
