import React from 'react';
import cx from 'classnames';
import { LineMediumShadow, LineSmallShadow } from '@/assets/shadow';

interface Iprops {
  variant: 'small' | 'medium' | 'large';
  className?: string;
}
const Line: React.FC<Iprops> = ({ variant, className }) => {
  const renderShadow = (variant: 'small' | 'medium' | 'large') => {
    switch (variant) {
      case 'small':
        return (
          <LineSmallShadow className="vertical-center absolute inset-x-0 -z-10 w-full" />
        );
      case 'medium':
        return (
          <LineMediumShadow className="vertical-center absolute inset-x-0 -z-10 w-full " />
        );
      default:
        return (
          <LineSmallShadow className="vertical-center absolute inset-x-0 -z-10  w-full" />
        );
    }
  };
  return (
    <div className={cx('relative h-px w-full', className)}>
      {renderShadow(variant)}
    </div>
  );
};

export default Line;
