export const RobotIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 79 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M56.9027 62H34.3186C29.7443 62 26.0215 58.2773 26.0215 53.703V46.5149C26.0215 39.1089 32.0413 33.0891 39.4473 33.0891H51.774C59.18 33.0891 65.1998 39.1089 65.1998 46.5149V53.703C65.1998 58.2773 61.477 62 56.9027 62ZM39.4473 34.1287C32.6156 34.1287 27.0512 39.6832 27.0512 46.5248V53.7129C27.0512 57.7129 30.3086 60.9703 34.3087 60.9703H56.9027C60.9027 60.9703 64.1602 57.7129 64.1602 53.7129V46.5248C64.1602 39.6931 58.6057 34.1287 51.7641 34.1287H39.4473Z"
        fill="url(#paint0_linear_703_877)"
      />
      <path
        d="M37.7938 48.0594C35.9225 48.0594 34.4077 46.5346 34.4077 44.6733C34.4077 42.802 35.9324 41.2871 37.7938 41.2871C39.6552 41.2871 41.1799 42.8119 41.1799 44.6733C41.1898 46.5445 39.6651 48.0594 37.7938 48.0594ZM37.7938 42.3168C36.4968 42.3168 35.4374 43.3762 35.4374 44.6733C35.4374 45.9703 36.4968 47.0297 37.7938 47.0297C39.0908 47.0297 40.1502 45.9703 40.1502 44.6733C40.1502 43.3762 39.0908 42.3168 37.7938 42.3168Z"
        fill="url(#paint1_linear_703_877)"
      />
      <path
        d="M53.4277 48.0594C51.5564 48.0594 50.0416 46.5346 50.0416 44.6733C50.0416 42.802 51.5663 41.2871 53.4277 41.2871C55.299 41.2871 56.8139 42.8119 56.8139 44.6733C56.8139 46.5445 55.299 48.0594 53.4277 48.0594ZM53.4277 42.3168C52.1307 42.3168 51.0713 43.3762 51.0713 44.6733C51.0713 45.9703 52.1307 47.0297 53.4277 47.0297C54.7247 47.0297 55.7841 45.9703 55.7841 44.6733C55.7841 43.3762 54.7247 42.3168 53.4277 42.3168Z"
        fill="url(#paint2_linear_703_877)"
      />
      <path
        d="M45.6158 56.1784C42.8435 56.1784 40.8435 55.0892 39.695 52.9308C39.5563 52.6734 39.6554 52.3665 39.9029 52.2279C40.1603 52.0892 40.4672 52.1882 40.6058 52.4358C41.5761 54.2477 43.2197 55.1288 45.6158 55.1288C48.0118 55.1288 49.6455 54.2477 50.6257 52.4358C50.7643 52.1882 51.0712 52.0892 51.3286 52.2279C51.5861 52.3665 51.6752 52.6734 51.5366 52.9308C50.3781 55.0892 48.388 56.1784 45.6158 56.1784Z"
        fill="url(#paint3_linear_703_877)"
      />
      <path
        d="M66.0911 54.2278H64.6851C64.398 54.2278 64.1703 54 64.1703 53.7129V46.2277C64.1703 45.9406 64.398 45.7129 64.6851 45.7129H66.0911C67.3485 45.7129 68.3683 46.7327 68.3683 47.9901V51.9505C68.3683 53.1981 67.3485 54.2278 66.0911 54.2278ZM65.2 53.1882H66.0812C66.7644 53.1882 67.3188 52.6337 67.3188 51.9505V47.9901C67.3188 47.307 66.7644 46.7525 66.0812 46.7525H65.2V53.1882Z"
        fill="url(#paint4_linear_703_877)"
      />
      <path
        d="M26.5364 54.2278H25.1305C23.873 54.2278 22.8532 53.208 22.8532 51.9505V47.9901C22.8532 46.7327 23.873 45.7129 25.1305 45.7129H26.5364C26.8235 45.7129 27.0513 45.9406 27.0513 46.2277V53.7129C27.0612 53.9901 26.8235 54.2278 26.5364 54.2278ZM25.1305 46.7426C24.4473 46.7426 23.8928 47.2971 23.8928 47.9802V51.9406C23.8928 52.6238 24.4473 53.1782 25.1305 53.1782H26.0117V46.7327H25.1305V46.7426Z"
        fill="url(#paint5_linear_703_877)"
      />
      <path
        d="M45.6157 27.0695C43.6355 27.0695 42.0118 25.4556 42.0118 23.4655C42.0118 21.4853 43.6256 19.8616 45.6157 19.8616C47.5959 19.8616 49.2197 21.4754 49.2197 23.4655C49.2098 25.4556 47.5959 27.0695 45.6157 27.0695ZM45.6157 20.9111C44.1999 20.9111 43.0514 22.0596 43.0514 23.4754C43.0514 24.8913 44.1999 26.0398 45.6157 26.0398C47.0316 26.0398 48.1801 24.8913 48.1801 23.4754C48.1702 22.0596 47.0217 20.9111 45.6157 20.9111Z"
        fill="url(#paint6_linear_703_877)"
      />
      <path
        d="M44.6157 24.8618C44.4672 24.8618 44.3286 24.8024 44.2296 24.6835C43.9325 24.3469 43.7741 23.9212 43.7741 23.4756C43.7741 22.4657 44.5959 21.634 45.6157 21.634C45.9028 21.634 46.1305 21.8618 46.1305 22.1489C46.1305 22.436 45.9028 22.6637 45.6157 22.6637C45.1702 22.6637 44.8137 23.0202 44.8137 23.4657C44.8137 23.6637 44.883 23.8519 45.0117 23.9905C45.1999 24.2083 45.1801 24.535 44.9622 24.7231C44.8632 24.8222 44.7444 24.8618 44.6157 24.8618Z"
        fill="url(#paint7_linear_703_877)"
      />
      <path
        d="M46.9325 34.1284H44.289C44.0018 34.1284 43.7741 33.9006 43.7741 33.6135V26.2472C43.7741 26.0689 43.8632 25.9006 44.0117 25.8115C44.1602 25.7125 44.3484 25.7026 44.5167 25.7818C45.2296 26.1184 45.9919 26.1184 46.7147 25.7818C46.8731 25.7026 47.0612 25.7125 47.2197 25.8115C47.3682 25.9105 47.4573 26.0689 47.4573 26.2472V33.6135C47.4474 33.8907 47.2197 34.1284 46.9325 34.1284ZM44.8137 33.0887H46.4177V26.9798C45.8929 27.1085 45.3484 27.1085 44.8137 26.9798V33.0887Z"
        fill="url(#paint8_linear_703_877)"
      />
      <path
        d="M54.8928 58.7622H36.3284C33.0908 58.7622 30.4571 56.1285 30.4571 52.8909V46.2572C30.4571 41.0592 34.6849 36.8215 39.8928 36.8215H51.3383C56.5364 36.8215 60.774 41.0493 60.774 46.2572V52.8909C60.7641 56.1285 58.1304 58.7622 54.8928 58.7622ZM39.8928 37.8512C35.269 37.8512 31.4967 41.6136 31.4967 46.2473V52.881C31.4967 55.5443 33.665 57.7127 36.3284 57.7127H54.8829C57.5463 57.7127 59.7146 55.5443 59.7146 52.881V46.2473C59.7146 41.6235 55.9522 37.8512 51.3185 37.8512H39.8928Z"
        fill="url(#paint9_linear_703_877)"
      />
      <path
        d="M77.4374 22.7525C77.1799 22.7525 76.9324 22.6436 76.7542 22.4456L73.3384 18.6931C72.2294 17.4753 70.6453 16.7723 68.9918 16.7723H43.3284C38.7046 16.7723 34.9423 13.0099 34.9423 8.38615C34.9423 3.76238 38.7046 0 43.3284 0C43.6155 0 43.8433 0.227723 43.8433 0.514852C43.8433 0.801981 43.6155 1.0297 43.3284 1.0297C39.2789 1.0297 35.9819 4.32674 35.9819 8.37625C35.9819 12.4258 39.2789 15.7228 43.3284 15.7228H68.9918C70.9324 15.7228 72.8037 16.5446 74.1106 17.9901L77.3384 21.5446V8.38615C77.3384 4.33664 74.0413 1.03961 69.9918 1.03961C69.7047 1.03961 69.477 0.801981 69.477 0.514852C69.477 0.227723 69.7047 0 69.9918 0C74.6156 0 78.378 3.76238 78.378 8.38615V21.8218C78.378 22.208 78.1403 22.5545 77.7839 22.6931C77.6651 22.7327 77.5562 22.7525 77.4374 22.7525Z"
        fill="url(#paint10_linear_703_877)"
      />
      <path
        d="M69.992 1.03971H68.9524C68.6652 1.03971 68.4375 0.811991 68.4375 0.524862C68.4375 0.237733 68.6652 0.0100098 68.9524 0.0100098H69.992C70.2791 0.0100098 70.5068 0.237733 70.5068 0.524862C70.5068 0.80209 70.2791 1.03971 69.992 1.03971Z"
        fill="url(#paint11_linear_703_877)"
      />
      <path
        d="M66.7147 1.03961H64.477C64.1899 1.03961 63.9622 0.811882 63.9622 0.524753C63.9622 0.237624 64.1899 0.009901 64.477 0.009901H66.7147C67.0018 0.009901 67.2295 0.237624 67.2295 0.524753C67.2394 0.801981 67.0018 1.03961 66.7147 1.03961ZM62.2493 1.03961H60.0117C59.7246 1.03961 59.4968 0.811882 59.4968 0.524753C59.4968 0.237624 59.7246 0.009901 60.0117 0.009901H62.2493C62.5364 0.009901 62.7642 0.237624 62.7642 0.524753C62.7642 0.801981 62.5364 1.03961 62.2493 1.03961ZM57.7741 1.03961H55.5364C55.2493 1.03961 55.0216 0.811882 55.0216 0.524753C55.0216 0.227723 55.2592 0 55.5463 0H57.784C58.0711 0 58.2988 0.227723 58.2988 0.514852C58.2988 0.801981 58.0612 1.03961 57.7741 1.03961ZM53.3087 1.03961H51.0711C50.7839 1.03961 50.5562 0.811882 50.5562 0.524753C50.5562 0.237624 50.7839 0.009901 51.0711 0.009901H53.3087C53.5958 0.009901 53.8236 0.237624 53.8236 0.524753C53.8236 0.801981 53.5958 1.03961 53.3087 1.03961ZM48.8335 1.03961H46.6057C46.3186 1.03961 46.0909 0.811882 46.0909 0.524753C46.081 0.227723 46.3186 0 46.6057 0H48.8434C49.1305 0 49.3582 0.227723 49.3582 0.514852C49.3582 0.801981 49.1206 1.03961 48.8335 1.03961Z"
        fill="url(#paint12_linear_703_877)"
      />
      <path
        d="M44.3681 1.03961H43.3285C43.0414 1.03961 42.8136 0.811882 42.8136 0.524753C42.8136 0.227723 43.0414 0 43.3285 0H44.3681C44.6552 0 44.8829 0.227723 44.8829 0.514852C44.8829 0.801981 44.6552 1.03961 44.3681 1.03961Z"
        fill="url(#paint13_linear_703_877)"
      />
      <path
        d="M68.8533 5.03947H45.8929C45.6058 5.03947 45.3781 4.81175 45.3781 4.52462C45.3781 4.23749 45.6058 4.00977 45.8929 4.00977H68.8533C69.1405 4.00977 69.3682 4.23749 69.3682 4.52462C69.3682 4.80185 69.1405 5.03947 68.8533 5.03947Z"
        fill="url(#paint14_linear_703_877)"
      />
      <path
        d="M72.0315 8.48527H42.7245C42.4374 8.48527 42.2097 8.25755 42.2097 7.97042C42.2097 7.68329 42.4374 7.45557 42.7245 7.45557H72.0315C72.3186 7.45557 72.5463 7.68329 72.5463 7.97042C72.5463 8.25755 72.3087 8.48527 72.0315 8.48527Z"
        fill="url(#paint15_linear_703_877)"
      />
      <path
        d="M72.0315 11.9406H42.7245C42.4374 11.9406 42.2097 11.7129 42.2097 11.4257C42.2097 11.1386 42.4374 10.9109 42.7245 10.9109H72.0315C72.3186 10.9109 72.5463 11.1386 72.5463 11.4257C72.5463 11.7129 72.3087 11.9406 72.0315 11.9406Z"
        fill="url(#paint16_linear_703_877)"
      />
      <path
        d="M1.10074 28.4952C0.991827 28.4952 0.873015 28.4754 0.764104 28.4358C0.407668 28.2873 0.170044 27.9506 0.170044 27.5645V14.1288C0.170044 9.50506 3.93243 5.74268 8.5562 5.74268C8.84332 5.74268 9.07105 5.9704 9.07105 6.25753C9.07105 6.54466 8.84332 6.77238 8.5562 6.77238C4.50668 6.77238 1.20965 10.0694 1.20965 14.1189V27.2774L4.43738 23.7229C5.74431 22.2873 7.6057 21.4556 9.5562 21.4556H35.2196C37.5562 21.4556 39.6949 20.3863 41.1008 18.515C41.2691 18.2872 41.5958 18.2377 41.8236 18.416C42.0513 18.5843 42.1008 18.911 41.9226 19.1387C40.3186 21.2675 37.8731 22.4952 35.2097 22.4952H9.5562C7.90273 22.4952 6.31857 23.1981 5.20966 24.416L1.79381 28.1685C1.61559 28.3863 1.35816 28.4952 1.10074 28.4952Z"
        fill="url(#paint17_linear_703_877)"
      />
      <path
        d="M9.58585 6.78215H8.55614C8.26901 6.78215 8.04129 6.55442 8.04129 6.26729C8.04129 5.98016 8.26901 5.75244 8.55614 5.75244H9.59575C9.88288 5.75244 10.1106 5.98016 10.1106 6.26729C10.1106 6.55442 9.87298 6.78215 9.58585 6.78215Z"
        fill="url(#paint18_linear_703_877)"
      />
      <path
        d="M29.4771 6.78215H27.487C27.1998 6.78215 26.9721 6.55442 26.9721 6.26729C26.9721 5.98016 27.1998 5.75244 27.487 5.75244H29.4771C29.7642 5.75244 29.9919 5.98016 29.9919 6.26729C29.9919 6.55442 29.7642 6.78215 29.4771 6.78215ZM25.4969 6.78215H23.5068C23.2196 6.78215 22.9919 6.55442 22.9919 6.26729C22.9919 5.98016 23.2196 5.75244 23.5068 5.75244H25.4969C25.784 5.75244 26.0117 5.98016 26.0117 6.26729C26.0117 6.55442 25.784 6.78215 25.4969 6.78215ZM21.5166 6.78215H19.5265C19.2394 6.78215 19.0117 6.55442 19.0117 6.26729C19.0117 5.98016 19.2394 5.75244 19.5265 5.75244H21.5166C21.8038 5.75244 22.0315 5.98016 22.0315 6.26729C22.0414 6.55442 21.8038 6.78215 21.5166 6.78215ZM17.5463 6.78215H15.5562C15.2691 6.78215 15.0414 6.55442 15.0414 6.26729C15.0414 5.98016 15.2691 5.75244 15.5562 5.75244H17.5463C17.8335 5.75244 18.0612 5.98016 18.0612 6.26729C18.0612 6.55442 17.8335 6.78215 17.5463 6.78215ZM13.5661 6.78215H11.576C11.2889 6.78215 11.0612 6.55442 11.0612 6.26729C11.0612 5.98016 11.2889 5.75244 11.576 5.75244H13.5661C13.8533 5.75244 14.081 5.98016 14.081 6.26729C14.0909 6.55442 13.8533 6.78215 13.5661 6.78215Z"
        fill="url(#paint19_linear_703_877)"
      />
      <path
        d="M32.4968 6.78215H31.4572C31.17 6.78215 30.9423 6.55442 30.9423 6.26729C30.9423 5.98016 31.17 5.75244 31.4572 5.75244H32.4968C32.7839 5.75244 33.0116 5.98016 33.0116 6.26729C33.0215 6.55442 32.7839 6.78215 32.4968 6.78215Z"
        fill="url(#paint20_linear_703_877)"
      />
      <path
        d="M32.6552 10.7821H9.69481C9.40768 10.7821 9.17996 10.5544 9.17996 10.2673C9.17996 9.98016 9.40768 9.75244 9.69481 9.75244H32.6552C32.9424 9.75244 33.1701 9.98016 33.1701 10.2673C33.1701 10.5544 32.9424 10.7821 32.6552 10.7821Z"
        fill="url(#paint21_linear_703_877)"
      />
      <path
        d="M35.8235 14.2279H6.51653C6.2294 14.2279 6.00168 14.0002 6.00168 13.7131C6.00168 13.426 6.2294 13.1982 6.51653 13.1982H35.8235C36.1106 13.1982 36.3384 13.426 36.3384 13.7131C36.3483 14.0002 36.1106 14.2279 35.8235 14.2279Z"
        fill="url(#paint22_linear_703_877)"
      />
      <path
        d="M35.8235 17.683H6.51653C6.2294 17.683 6.00168 17.4553 6.00168 17.1682C6.00168 16.881 6.2294 16.6533 6.51653 16.6533H35.8235C36.1106 16.6533 36.3384 16.881 36.3384 17.1682C36.3483 17.4454 36.1106 17.683 35.8235 17.683Z"
        fill="url(#paint23_linear_703_877)"
      />
      <path
        d="M46.9325 30.4455H44.289C44.0018 30.4455 43.7741 30.2178 43.7741 29.9306C43.7741 29.6435 44.0018 29.4158 44.289 29.4158H46.9325C47.2197 29.4158 47.4474 29.6435 47.4474 29.9306C47.4474 30.2178 47.2197 30.4455 46.9325 30.4455Z"
        fill="url(#paint24_linear_703_877)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_703_877"
          x1="45.6107"
          y1="33.0891"
          x2="45.6107"
          y2="62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_877"
          x1="37.7938"
          y1="41.2871"
          x2="37.7938"
          y2="48.0594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_703_877"
          x1="53.4277"
          y1="41.2871"
          x2="53.4277"
          y2="48.0594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_703_877"
          x1="45.6168"
          y1="52.1648"
          x2="45.6168"
          y2="56.1784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_703_877"
          x1="66.2693"
          y1="45.7129"
          x2="66.2693"
          y2="54.2278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_703_877"
          x1="24.9524"
          y1="45.7129"
          x2="24.9524"
          y2="54.2278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_703_877"
          x1="45.6157"
          y1="19.8616"
          x2="45.6157"
          y2="27.0695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_703_877"
          x1="44.9523"
          y1="21.634"
          x2="44.9523"
          y2="24.8618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_703_877"
          x1="45.6157"
          y1="25.729"
          x2="45.6157"
          y2="34.1284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_703_877"
          x1="45.6156"
          y1="36.8215"
          x2="45.6156"
          y2="58.7622"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_703_877"
          x1="56.6601"
          y1="0"
          x2="56.6601"
          y2="22.7525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_703_877"
          x1="69.4722"
          y1="0.0100098"
          x2="69.4722"
          y2="1.03971"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_703_877"
          x1="56.6602"
          y1="0"
          x2="56.6602"
          y2="1.03961"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_703_877"
          x1="43.8483"
          y1="0"
          x2="43.8483"
          y2="1.03961"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_703_877"
          x1="57.3731"
          y1="4.00977"
          x2="57.3731"
          y2="5.03947"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_703_877"
          x1="57.378"
          y1="7.45557"
          x2="57.378"
          y2="8.48527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_703_877"
          x1="57.378"
          y1="10.9109"
          x2="57.378"
          y2="11.9406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_703_877"
          x1="21.1002"
          y1="5.74268"
          x2="21.1002"
          y2="28.4952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_703_877"
          x1="9.07595"
          y1="5.75244"
          x2="9.07595"
          y2="6.78215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_703_877"
          x1="20.5265"
          y1="5.75244"
          x2="20.5265"
          y2="6.78215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_703_877"
          x1="31.9771"
          y1="5.75244"
          x2="31.9771"
          y2="6.78215"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_703_877"
          x1="21.175"
          y1="9.75244"
          x2="21.175"
          y2="10.7821"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_703_877"
          x1="21.1702"
          y1="13.1982"
          x2="21.1702"
          y2="14.2279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_703_877"
          x1="21.1702"
          y1="16.6533"
          x2="21.1702"
          y2="17.683"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_703_877"
          x1="45.6107"
          y1="29.4158"
          x2="45.6107"
          y2="30.4455"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
      </defs>
    </svg>
  );
};
