export const ProcessShadow = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 1920 3662"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_703_30)">
        <rect
          x="2394.72"
          y="1682.14"
          width="1717.89"
          height="2327.11"
          transform="rotate(120.53 2394.72 1682.14)"
          fill="url(#paint0_linear_703_30)"
          fillOpacity="0.45"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_703_30"
          x="-982.432"
          y="0"
          width="3877.16"
          height="3661.87"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_703_30"
          />
        </filter>
        <linearGradient
          id="paint0_linear_703_30"
          x1="3008.26"
          y1="1117.99"
          x2="4399.01"
          y2="1263.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
      </defs>
    </svg>
  );
};
