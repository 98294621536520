import Header from './header';
import Footer from './footer';

type Props = {
  children?: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  return (
    <>
      <Header />
      <main id="js-modal">
        <div />
        {children}
      </main>
      <Footer />
    </>
  );
}
