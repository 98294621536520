export const MenuIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 65 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      width={48}
    >
      <rect y="13" width="41" height="4" fill="white" />
      <rect width="65" height="4" fill="white" />
    </svg>
  );
};
