export const TalkIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 74 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.734 15.3701H17.614C16.884 15.3701 16.294 14.7801 16.294 14.0501V10.4501C16.294 9.48009 16.044 8.52009 15.574 7.68009C15.244 7.08009 15.244 6.37009 15.594 5.78009C15.944 5.19009 16.554 4.84009 17.244 4.84009H26.594C29.944 4.84009 32.674 7.57009 32.674 10.9201V13.6401C32.674 14.3701 32.084 14.9601 31.354 14.9601C30.624 14.9601 30.034 14.3701 30.034 13.6401V11.7401C30.034 11.4801 29.914 11.2501 29.694 11.1101C29.474 10.9701 29.214 10.9501 28.984 11.0501C27.134 11.8801 25.164 12.3001 23.144 12.3001H22.904C21.604 12.3001 20.294 12.0501 19.074 11.5801V14.0501C19.054 14.7801 18.464 15.3701 17.734 15.3701ZM17.244 5.89009C16.934 5.89009 16.654 6.05009 16.504 6.32009C16.344 6.59009 16.344 6.91009 16.494 7.18009C17.054 8.18009 17.354 9.32009 17.354 10.4701V14.0701C17.354 14.2201 17.474 14.3401 17.624 14.3401H17.744C17.894 14.3401 18.014 14.2201 18.014 14.0701V10.8001C18.014 10.6201 18.104 10.4501 18.264 10.3601C18.414 10.2601 18.604 10.2501 18.774 10.3301C20.054 10.9501 21.474 11.2801 22.904 11.2801H23.144C25.024 11.2801 26.844 10.8901 28.554 10.1201C29.114 9.87009 29.754 9.92009 30.264 10.2501C30.774 10.5801 31.084 11.1501 31.084 11.7601V13.6601C31.084 13.8101 31.204 13.9301 31.354 13.9301C31.504 13.9301 31.624 13.8101 31.624 13.6601V10.9401C31.624 8.17009 29.364 5.91009 26.594 5.91009H17.244V5.89009Z"
        fill="url(#paint0_linear_703_831)"
      />
      <path
        d="M23.514 10.1399C22.454 10.1399 20.944 10.0099 19.574 9.39994C19.304 9.27994 19.194 8.96994 19.304 8.69994C19.424 8.43994 19.734 8.31994 19.994 8.42994C22.104 9.36994 24.734 9.00994 24.764 9.00994C25.044 8.96994 25.314 9.16994 25.354 9.44994C25.394 9.73994 25.194 9.99994 24.914 10.0399C24.864 10.0599 24.314 10.1399 23.514 10.1399Z"
        fill="url(#paint1_linear_703_831)"
      />
      <path
        d="M25.214 23.97H23.644C22.424 23.97 21.254 23.68 20.284 23.12C18.214 21.93 16.924 19.73 16.924 17.36V14.84C16.924 14.68 16.994 14.53 17.124 14.43C17.244 14.33 17.404 14.29 17.564 14.33C17.584 14.33 17.604 14.34 17.624 14.34H17.744C17.894 14.34 18.014 14.22 18.014 14.07V10.8C18.014 10.62 18.104 10.45 18.264 10.36C18.414 10.26 18.604 10.25 18.774 10.33C20.054 10.95 21.474 11.28 22.904 11.28H23.144C24.824 11.28 26.624 10.89 28.634 10.09C29.184 9.86999 29.814 9.93999 30.304 10.27C30.794 10.6 31.094 11.16 31.094 11.76V13.66C31.094 13.8 31.204 13.92 31.354 13.93C31.634 13.94 31.854 14.17 31.854 14.45V17.27C31.854 18.14 31.714 18.94 31.454 19.65C30.444 22.27 28.004 23.97 25.214 23.97ZM17.974 15.35V17.35C17.974 19.34 19.064 21.2 20.804 22.2C21.624 22.67 22.604 22.91 23.644 22.91H25.214C27.564 22.91 29.614 21.48 30.444 19.26C30.664 18.66 30.784 17.98 30.784 17.24V14.82C30.334 14.61 30.024 14.15 30.024 13.63V11.73C30.024 11.48 29.904 11.25 29.694 11.11C29.484 10.97 29.234 10.94 29.004 11.03C26.874 11.88 24.954 12.29 23.134 12.29H22.894C21.594 12.29 20.284 12.04 19.064 11.57V14.04C19.054 14.7 18.594 15.24 17.974 15.35Z"
        fill="url(#paint2_linear_703_831)"
      />
      <path
        d="M0.524027 42.45C0.464027 42.45 0.404027 42.44 0.344027 42.42C0.114027 42.33 -0.0259727 42.09 0.00402733 41.85C0.0140273 41.8 0.764027 36.42 2.51403 31.42C3.35403 29.01 3.18403 26.4 2.05403 24.25C0.954027 22.16 0.394027 19.96 0.394027 17.69C0.404027 7.94 10.924 0 23.854 0C36.784 0 47.304 7.94 47.304 17.7C47.304 27.46 36.784 35.4 23.854 35.4C21.334 35.4 18.854 35.1 16.484 34.51C14.534 34.03 12.434 34.2 10.564 35.01C5.99403 36.97 2.77403 39.39 0.974027 42.21C0.874027 42.36 0.704027 42.45 0.524027 42.45ZM23.854 1.05C11.504 1.05 1.45403 8.52 1.45403 17.7C1.45403 19.79 1.97403 21.84 2.99403 23.77C4.26403 26.17 4.45403 29.08 3.51403 31.77C2.46403 34.77 1.78403 37.91 1.40403 39.91C3.40403 37.65 6.33403 35.68 10.154 34.04C12.234 33.15 14.574 32.95 16.744 33.49C19.034 34.06 21.424 34.35 23.854 34.35C36.204 34.35 46.254 26.88 46.254 17.7C46.254 8.52 36.204 1.05 23.854 1.05Z"
        fill="url(#paint3_linear_703_831)"
      />
      <path
        d="M23.854 35.39C21.334 35.39 18.854 35.09 16.484 34.5C15.674 34.3 14.814 34.21 13.944 34.24C13.804 34.25 13.664 34.19 13.564 34.09C13.464 33.99 13.404 33.85 13.404 33.71V30.83C13.404 28.29 15.474 26.22 18.014 26.22H20.964C21.094 26.22 21.204 26.11 21.204 25.98V23.15C21.204 22.98 21.284 22.83 21.414 22.73C21.544 22.63 21.724 22.6 21.884 22.65C22.444 22.82 23.044 22.91 23.664 22.91H25.234C25.804 22.91 26.364 22.82 26.904 22.65C27.064 22.6 27.234 22.63 27.374 22.73C27.504 22.83 27.584 22.99 27.584 23.15V25.98C27.584 26.11 27.694 26.22 27.824 26.22H30.774C33.314 26.22 35.384 28.29 35.384 30.83V32.76C35.384 32.97 35.254 33.16 35.054 33.25C31.624 34.65 27.754 35.39 23.854 35.39ZM14.454 33.19C15.244 33.2 16.004 33.3 16.744 33.48C19.034 34.05 21.424 34.34 23.854 34.34C27.504 34.34 31.104 33.67 34.314 32.41V30.83C34.314 28.87 32.714 27.27 30.754 27.27H27.804C27.094 27.27 26.524 26.69 26.524 25.99V23.84C26.094 23.93 25.664 23.97 25.224 23.97H23.654C23.174 23.97 22.704 23.92 22.244 23.83V25.99C22.244 26.7 21.664 27.27 20.964 27.27H18.014C16.054 27.27 14.454 28.87 14.454 30.83V33.19Z"
        fill="url(#paint4_linear_703_831)"
      />
      <path
        d="M13.924 22.9499H6.78402C6.49402 22.9499 6.26402 22.7199 6.26402 22.4299C6.26402 22.1399 6.49402 21.9099 6.78402 21.9099H13.924C14.214 21.9099 14.444 22.1399 14.444 22.4299C14.454 22.7199 14.214 22.9499 13.924 22.9499Z"
        fill="url(#paint5_linear_703_831)"
      />
      <path
        d="M13.924 16.6601H6.78402C6.49402 16.6601 6.26402 16.4301 6.26402 16.1401C6.26402 15.8501 6.49402 15.6201 6.78402 15.6201H13.924C14.214 15.6201 14.444 15.8501 14.444 16.1401C14.454 16.4301 14.214 16.6601 13.924 16.6601Z"
        fill="url(#paint6_linear_703_831)"
      />
      <path
        d="M41.974 22.9499H34.834C34.544 22.9499 34.314 22.7199 34.314 22.4299C34.314 22.1399 34.544 21.9099 34.834 21.9099H41.974C42.264 21.9099 42.494 22.1399 42.494 22.4299C42.504 22.7199 42.264 22.9499 41.974 22.9499Z"
        fill="url(#paint7_linear_703_831)"
      />
      <path
        d="M41.974 16.6601H34.834C34.544 16.6601 34.314 16.4301 34.314 16.1401C34.314 15.8501 34.544 15.6201 34.834 15.6201H41.974C42.264 15.6201 42.494 15.8501 42.494 16.1401C42.504 16.4301 42.264 16.6601 41.974 16.6601Z"
        fill="url(#paint8_linear_703_831)"
      />
      <path
        d="M57.514 56.3801H41.604C39.534 56.3801 37.854 54.7001 37.854 52.6301V43.7401C37.854 41.1601 39.954 39.05 42.534 39.05H56.574C59.154 39.05 61.254 41.1501 61.254 43.7401V52.6301C61.264 54.7001 59.584 56.3801 57.514 56.3801ZM42.534 40.1C40.524 40.1 38.894 41.7301 38.894 43.7401V52.6301C38.894 54.1201 40.104 55.3301 41.594 55.3301H57.504C58.994 55.3301 60.204 54.1201 60.204 52.6301V43.7401C60.204 41.7301 58.574 40.1 56.564 40.1H42.534Z"
        fill="url(#paint9_linear_703_831)"
      />
      <path
        d="M56.764 53.94H42.344C41.014 53.94 39.934 52.86 39.934 51.53L39.924 45.23C39.924 45.09 39.984 44.96 40.074 44.86C40.174 44.76 40.304 44.71 40.444 44.71H58.644C58.934 44.71 59.164 44.94 59.164 45.23L59.174 51.53C59.184 52.86 58.104 53.94 56.764 53.94ZM40.974 45.75L40.984 51.53C40.984 52.28 41.594 52.9 42.354 52.9H56.774C57.524 52.9 58.144 52.29 58.144 51.53L58.134 45.75H40.974Z"
        fill="url(#paint10_linear_703_831)"
      />
      <path
        d="M60.614 43.34H38.484C38.194 43.34 37.964 43.11 37.964 42.82C37.964 42.53 38.194 42.3 38.484 42.3H60.624C60.914 42.3 61.144 42.53 61.144 42.82C61.144 43.1 60.904 43.34 60.614 43.34Z"
        fill="url(#paint11_linear_703_831)"
      />
      <path
        d="M44.224 50.5399C43.154 50.5399 42.274 49.6699 42.274 48.5899C42.274 47.5199 43.144 46.6399 44.224 46.6399C45.294 46.6399 46.174 47.5099 46.174 48.5899C46.174 49.6699 45.294 50.5399 44.224 50.5399ZM44.224 47.6999C43.734 47.6999 43.324 48.0999 43.324 48.5999C43.324 49.0999 43.724 49.4999 44.224 49.4999C44.724 49.4999 45.124 49.0999 45.124 48.5999C45.124 48.0999 44.724 47.6999 44.224 47.6999Z"
        fill="url(#paint12_linear_703_831)"
      />
      <path
        d="M54.894 50.5399C53.824 50.5399 52.944 49.6699 52.944 48.5899C52.944 47.5199 53.814 46.6399 54.894 46.6399C55.964 46.6399 56.844 47.5099 56.844 48.5899C56.834 49.6699 55.964 50.5399 54.894 50.5399ZM54.894 47.6999C54.404 47.6999 53.994 48.0999 53.994 48.5999C53.994 49.0999 54.394 49.4999 54.894 49.4999C55.394 49.4999 55.794 49.0999 55.794 48.5999C55.794 48.0999 55.384 47.6999 54.894 47.6999Z"
        fill="url(#paint13_linear_703_831)"
      />
      <path
        d="M51.864 58.7201H47.254C46.964 58.7201 46.734 58.4901 46.734 58.2001V55.8601C46.734 55.5701 46.964 55.3401 47.254 55.3401H51.864C52.154 55.3401 52.384 55.5701 52.384 55.8601V58.2001C52.384 58.4901 52.154 58.7201 51.864 58.7201ZM47.774 57.6801H51.334V56.3901H47.774V57.6801Z"
        fill="url(#paint14_linear_703_831)"
      />
      <path
        d="M72.684 75.5601C72.514 75.5601 72.344 75.4701 72.244 75.3201C70.444 72.5001 67.214 70.0801 62.654 68.1201C60.784 67.3201 58.674 67.1401 56.734 67.6201C54.364 68.2101 51.884 68.5101 49.364 68.5101C36.434 68.5101 25.914 60.5701 25.914 50.8101C25.914 41.0501 36.434 33.1101 49.364 33.1101C62.294 33.1101 72.814 41.0501 72.814 50.8101C72.814 53.0801 72.254 55.2801 71.154 57.3701C70.024 59.5101 69.854 62.1201 70.694 64.5401C72.434 69.5401 73.194 74.9201 73.204 74.9701C73.234 75.2101 73.094 75.4501 72.864 75.5301C72.804 75.5501 72.744 75.5601 72.684 75.5601ZM58.924 66.3001C60.334 66.3001 61.744 66.5901 63.054 67.1501C66.874 68.7901 69.804 70.7601 71.804 73.0201C71.434 71.0201 70.744 67.8801 69.694 64.8801C68.754 62.1901 68.944 59.2801 70.214 56.8801C71.234 54.9501 71.754 52.9101 71.754 50.8101C71.754 41.6301 61.704 34.1601 49.354 34.1601C37.004 34.1601 26.954 41.6301 26.954 50.8101C26.954 59.9901 37.004 67.4601 49.364 67.4601C51.794 67.4601 54.194 67.1701 56.474 66.6001C57.274 66.4001 58.104 66.3001 58.924 66.3001Z"
        fill="url(#paint15_linear_703_831)"
      />
      <path
        d="M49.364 68.4999C46.924 68.4999 44.514 68.2199 42.194 67.6499C41.954 67.5899 41.794 67.3799 41.794 67.1399V59.5799C41.794 58.5199 42.654 57.6599 43.714 57.6599H55.424C56.484 57.6599 57.344 58.5199 57.344 59.5799V67.0499C57.344 67.2899 57.174 67.5099 56.934 67.5599L56.744 67.5999C54.354 68.2099 51.874 68.4999 49.364 68.4999ZM42.834 66.7299C47.204 67.7199 51.964 67.6799 56.274 66.6399V59.5899C56.274 59.1099 55.884 58.7199 55.404 58.7199H43.704C43.224 58.7199 42.834 59.1099 42.834 59.5899V66.7299Z"
        fill="url(#paint16_linear_703_831)"
      />
      <path
        d="M34.834 48.6101H30.754C30.464 48.6101 30.234 48.3801 30.234 48.0901C30.234 47.8001 30.464 47.5701 30.754 47.5701H34.834C35.124 47.5701 35.354 47.8001 35.354 48.0901C35.364 48.3801 35.124 48.6101 34.834 48.6101Z"
        fill="url(#paint17_linear_703_831)"
      />
      <path
        d="M34.834 53.27H30.754C30.464 53.27 30.234 53.04 30.234 52.75C30.234 52.46 30.464 52.23 30.754 52.23H34.834C35.124 52.23 35.354 52.46 35.354 52.75C35.364 53.04 35.124 53.27 34.834 53.27Z"
        fill="url(#paint18_linear_703_831)"
      />
      <path
        d="M67.934 48.6101H63.854C63.564 48.6101 63.334 48.3801 63.334 48.0901C63.334 47.8001 63.564 47.5701 63.854 47.5701H67.934C68.224 47.5701 68.454 47.8001 68.454 48.0901C68.464 48.3801 68.224 48.6101 67.934 48.6101Z"
        fill="url(#paint19_linear_703_831)"
      />
      <path
        d="M67.934 53.27H63.854C63.564 53.27 63.334 53.04 63.334 52.75C63.334 52.46 63.564 52.23 63.854 52.23H67.934C68.224 52.23 68.454 52.46 68.454 52.75C68.464 53.04 68.224 53.27 67.934 53.27Z"
        fill="url(#paint20_linear_703_831)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_703_831"
          x1="24.0015"
          y1="4.84009"
          x2="24.0015"
          y2="15.3701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_831"
          x1="22.3104"
          y1="8.38916"
          x2="22.3104"
          y2="10.1399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_703_831"
          x1="24.389"
          y1="9.96313"
          x2="24.389"
          y2="23.97"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_703_831"
          x1="23.652"
          y1="0"
          x2="23.652"
          y2="42.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_703_831"
          x1="24.394"
          y1="22.6262"
          x2="24.394"
          y2="35.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_703_831"
          x1="10.3542"
          y1="21.9099"
          x2="10.3542"
          y2="22.9499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_703_831"
          x1="10.3542"
          y1="15.6201"
          x2="10.3542"
          y2="16.6601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_703_831"
          x1="38.4042"
          y1="21.9099"
          x2="38.4042"
          y2="22.9499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_703_831"
          x1="38.4042"
          y1="15.6201"
          x2="38.4042"
          y2="16.6601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_703_831"
          x1="49.554"
          y1="39.05"
          x2="49.554"
          y2="56.3801"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_703_831"
          x1="49.5491"
          y1="44.71"
          x2="49.5491"
          y2="53.94"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_703_831"
          x1="49.554"
          y1="42.3"
          x2="49.554"
          y2="43.34"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_703_831"
          x1="44.224"
          y1="46.6399"
          x2="44.224"
          y2="50.5399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_703_831"
          x1="54.894"
          y1="46.6399"
          x2="54.894"
          y2="50.5399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_703_831"
          x1="49.559"
          y1="55.3401"
          x2="49.559"
          y2="58.7201"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_703_831"
          x1="49.561"
          y1="33.1101"
          x2="49.561"
          y2="75.5601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_703_831"
          x1="49.569"
          y1="57.6599"
          x2="49.569"
          y2="68.4999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_703_831"
          x1="32.7942"
          y1="47.5701"
          x2="32.7942"
          y2="48.6101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_703_831"
          x1="32.7942"
          y1="52.23"
          x2="32.7942"
          y2="53.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_703_831"
          x1="65.8942"
          y1="47.5701"
          x2="65.8942"
          y2="48.6101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_703_831"
          x1="65.8942"
          y1="52.23"
          x2="65.8942"
          y2="53.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
      </defs>
    </svg>
  );
};
