export const HowWorksShadow = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 1920 2126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_703_2)">
        <rect
          x="896.651"
          y="668.17"
          width="701.18"
          height="649.76"
          transform="rotate(105 896.651 668.17)"
          fill="url(#paint0_linear_703_2)"
          fillOpacity="0.45"
        />
      </g>
      <g filter="url(#filter1_f_703_2)">
        <rect
          x="1832.16"
          y="906.172"
          width="744.237"
          height="689.659"
          transform="rotate(105 1832.16 906.172)"
          fill="url(#paint1_linear_703_2)"
          fillOpacity="0.39"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_703_2"
          x="-412.448"
          y="9.15527e-05"
          width="1809.1"
          height="1845.46"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_703_2"
          />
        </filter>
        <filter
          id="filter1_f_703_2"
          x="473.38"
          y="227.675"
          width="1858.78"
          height="1897.37"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_703_2"
          />
        </filter>
        <linearGradient
          id="paint0_linear_703_2"
          x1="1147.07"
          y1="510.653"
          x2="1707.87"
          y2="596.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_2"
          x1="2097.96"
          y1="738.982"
          x2="2693.19"
          y2="829.725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
      </defs>
    </svg>
  );
};
