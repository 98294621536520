export const GlobeIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5 0C5.1589 0 0 5.1589 0 11.5C0 17.8411 5.1589 23 11.5 23C17.8411 23 23 17.8411 23 11.5C23 5.1589 17.8411 0 11.5 0ZM20.6979 7.66667H16.5797C16.2181 5.33696 15.5306 3.38867 14.619 2.04029C17.3661 2.94841 19.5891 5.0163 20.6979 7.66667ZM15.3333 11.5C15.3333 12.3033 15.2925 13.0694 15.2227 13.8H7.77726C7.70749 13.0694 7.66667 12.3033 7.66667 11.5C7.66667 10.6967 7.70749 9.93063 7.77726 9.2H15.2225C15.2925 9.93063 15.3333 10.6967 15.3333 11.5ZM11.5 21.4667C10.1158 21.4667 8.61369 19.076 7.97927 15.3333H15.0207C14.3863 19.076 12.8842 21.4667 11.5 21.4667ZM7.97927 7.66667C8.61369 3.92399 10.1158 1.53333 11.5 1.53333C12.8842 1.53333 14.3863 3.92399 15.0207 7.66667H7.97927ZM8.38101 2.04029C7.46944 3.38886 6.78174 5.33696 6.42026 7.66667H2.30211C3.4109 5.0163 5.63385 2.94841 8.38101 2.04029ZM1.81182 9.2H6.23568C6.1709 9.94002 6.13333 10.7063 6.13333 11.5C6.13333 12.2937 6.1709 13.06 6.23587 13.8H1.81182C1.63626 13.0602 1.53333 12.2927 1.53333 11.5C1.53333 10.7073 1.63626 9.93983 1.81182 9.2ZM2.30211 15.3333H6.42007C6.78174 17.663 7.46925 19.6113 8.38082 20.9597C5.63366 20.0516 3.4109 17.9837 2.30211 15.3333ZM14.619 20.9597C15.5306 19.6111 16.2183 17.663 16.5797 15.3333H20.6977C19.5891 17.9837 17.3663 20.0516 14.619 20.9597ZM21.1882 13.8H16.7641C16.8291 13.06 16.8667 12.2937 16.8667 11.5C16.8667 10.7063 16.8291 9.94002 16.7641 9.2H21.188C21.3637 9.93983 21.4665 10.7073 21.4665 11.5C21.4665 12.2927 21.3637 13.0602 21.1882 13.8Z"
        fill="white"
      />
    </svg>
  );
};
