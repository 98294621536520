import { from } from '@apollo/client';

export * from './bell';
export * from './brain';
export * from './calendar';
export * from './check';
export * from './chevronLeft';
export * from './chevronRight';
export * from './cloud';
export * from './computer';
export * from './gear';
export * from './globe';
export * from './hour';
export * from './idea';
export * from './info';
export * from './magnet';
export * from './menu';
export * from './minus';
export * from './minusCircle';
export * from './plus';
export * from './plusCircle';
export * from './prize';
export * from './rise';
export * from './robot';
export * from './rocket';
export * from './scan';
export * from './signal';
export * from './talk';
export * from './time';
export * from './trade';
export * from './upload';
export * from './wave';
export * from './lock';
export * from './card';
export * from './data';
