export const PriceShadow = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 1545 1968"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_f_703_45)">
        <rect
          x="1044.36"
          y="692.391"
          width="802.165"
          height="743.339"
          transform="rotate(105 1044.36 692.391)"
          fill="url(#paint0_linear_703_45)"
          fillOpacity="0.39"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_703_45"
          x="-381.263"
          y="0.000244141"
          width="1925.63"
          height="1967.22"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="250"
            result="effect1_foregroundBlur_703_45"
          />
        </filter>
        <linearGradient
          id="paint0_linear_703_45"
          x1="1330.85"
          y1="512.187"
          x2="1972.41"
          y2="609.993"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D009E" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
      </defs>
    </svg>
  );
};
