export const MagnetIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M56.75 14.2705C59.4381 14.2705 61.625 12.0836 61.625 9.39551C61.625 6.70738 59.4381 4.52051 56.75 4.52051C54.0619 4.52051 51.875 6.70738 51.875 9.39551C51.875 12.0836 54.0619 14.2705 56.75 14.2705ZM56.75 6.39551C58.4041 6.39551 59.75 7.74138 59.75 9.39551C59.75 11.0496 58.4041 12.3955 56.75 12.3955C55.0959 12.3955 53.75 11.0496 53.75 9.39551C53.75 7.74138 55.0959 6.39551 56.75 6.39551Z"
        fill="url(#paint0_linear_703_773)"
      />
      <path
        d="M56.7484 15.562H56.689C52.7421 15.562 49.5311 18.773 49.5311 22.7198V23.2795C49.5311 25.0436 50.9664 26.479 52.7306 26.479H60.7068C62.4919 26.479 64 24.9811 64 23.208V22.708C64 18.7676 60.747 15.562 56.7484 15.562ZM62.125 23.2079C62.125 23.9385 61.449 24.6039 60.7068 24.6039H52.7306C52.0003 24.6039 51.4061 24.0098 51.4061 23.2794V22.7196C51.4061 19.8068 53.776 17.4369 56.689 17.4369H56.7484C59.7131 17.4369 62.125 19.8014 62.125 22.7078V23.2079Z"
        fill="url(#paint1_linear_703_773)"
      />
      <path
        d="M61.625 42.3955C61.625 39.7074 59.4381 37.5205 56.75 37.5205C54.0619 37.5205 51.875 39.7074 51.875 42.3955C51.875 45.0836 54.0619 47.2705 56.75 47.2705C59.4381 47.2705 61.625 45.0836 61.625 42.3955ZM53.75 42.3955C53.75 40.7414 55.0959 39.3955 56.75 39.3955C58.4041 39.3955 59.75 40.7414 59.75 42.3955C59.75 44.0496 58.4041 45.3955 56.75 45.3955C55.0959 45.3955 53.75 44.0496 53.75 42.3955Z"
        fill="url(#paint2_linear_703_773)"
      />
      <path
        d="M56.7484 48.562H56.689C52.7421 48.562 49.5311 51.773 49.5311 55.7198V56.2795C49.5311 58.0436 50.9664 59.479 52.7306 59.479H60.7068C62.4919 59.479 64 57.9811 64 56.208V55.708C64 51.7676 60.747 48.562 56.7484 48.562ZM62.125 56.2079C62.125 56.9385 61.449 57.6039 60.7068 57.6039H52.7306C52.0003 57.6039 51.4061 57.0098 51.4061 56.2794V55.7196C51.4061 52.8068 53.776 50.4369 56.689 50.4369H56.7484C59.7131 50.4369 62.125 52.8014 62.125 55.7078V56.2079Z"
        fill="url(#paint3_linear_703_773)"
      />
      <path
        d="M36.1875 8.3125H23.6875C17.3679 8.3125 11.4208 10.7805 6.9415 15.2619C2.46575 19.7396 0.001125 25.6822 0.000125 31.9964L0 32L0.000125 32.0023C0.000125 32.0055 0 32.0086 0 32.0119C0.003375 38.9135 3.08712 45.4748 8.46037 50.0133C8.85388 50.3461 9.44613 50.299 9.7815 49.902C10.1156 49.5064 10.0658 48.915 9.67025 48.5809C4.96088 44.6033 2.1625 38.9443 1.89762 32.9375H12.5396C13.0175 38.6693 17.8344 43.1875 23.6875 43.1875H27.625V53.8125H23.8843C20.1414 53.8125 16.4284 52.8378 13.1464 50.9936C12.6951 50.74 12.1234 50.9002 11.8699 51.3516C11.6163 51.803 11.7765 52.3746 12.2279 52.6281C15.7896 54.6296 19.8204 55.6875 23.8843 55.6875H36.1875C36.7054 55.6875 37.125 55.2679 37.125 54.75V42.25C37.125 41.7321 36.7054 41.3125 36.1875 41.3125H23.6875C18.5526 41.3125 14.375 37.1349 14.375 32C14.375 29.6746 15.2368 27.4482 16.8013 25.7307C17.15 25.348 17.1224 24.755 16.7396 24.4064C16.357 24.0576 15.764 24.0854 15.4153 24.468C13.7468 26.2994 12.7455 28.6126 12.5409 31.0625H1.89587C2.12637 25.599 4.36725 20.4895 8.26763 16.5872C12.3929 12.4604 17.869 10.1875 23.6875 10.1875H27.625V20.8125H23.6875C22.1778 20.8125 20.7125 21.1084 19.3325 21.6921C18.8558 21.8938 18.6325 22.4439 18.8344 22.9207C19.036 23.3976 19.5861 23.6206 20.063 23.4189C21.2106 22.9335 22.4301 22.6874 23.6875 22.6874H36.1875C36.7054 22.6874 37.125 22.2677 37.125 21.7499V9.25C37.125 8.73212 36.7054 8.3125 36.1875 8.3125ZM35.25 53.8125H29.5V43.1875H35.25V53.8125ZM35.25 20.8125H29.5V10.1875H35.25V20.8125Z"
        fill="url(#paint4_linear_703_773)"
      />
      <path
        d="M39.8125 10.1875H46.5625C47.0804 10.1875 47.5 9.76787 47.5 9.25C47.5 8.73212 47.0804 8.3125 46.5625 8.3125H39.8125C39.2946 8.3125 38.875 8.73212 38.875 9.25C38.875 9.76787 39.2946 10.1875 39.8125 10.1875Z"
        fill="url(#paint5_linear_703_773)"
      />
      <path
        d="M39.125 21.75C39.125 22.2679 39.5446 22.6875 40.0625 22.6875H45.3125C45.8304 22.6875 46.25 22.2679 46.25 21.75C46.25 21.2321 45.8304 20.8125 45.3125 20.8125H40.0625C39.5446 20.8125 39.125 21.2321 39.125 21.75Z"
        fill="url(#paint6_linear_703_773)"
      />
      <path
        d="M46.0625 15.5C46.0625 16.0179 46.4821 16.4375 47 16.4375H49.6875C50.2054 16.4375 50.625 16.0179 50.625 15.5C50.625 14.9821 50.2054 14.5625 49.6875 14.5625H47C46.4821 14.5625 46.0625 14.9821 46.0625 15.5Z"
        fill="url(#paint7_linear_703_773)"
      />
      <path
        d="M43 16.4375C43.5179 16.4375 43.9375 16.0179 43.9375 15.5C43.9375 14.9821 43.5179 14.5625 43 14.5625H40.5625C40.0446 14.5625 39.625 14.9821 39.625 15.5C39.625 16.0179 40.0446 16.4375 40.5625 16.4375H43Z"
        fill="url(#paint8_linear_703_773)"
      />
      <path
        d="M46.5625 53.8125H39.8125C39.2946 53.8125 38.875 54.2321 38.875 54.75C38.875 55.2679 39.2946 55.6875 39.8125 55.6875H46.5625C47.0804 55.6875 47.5 55.2679 47.5 54.75C47.5 54.2321 47.0804 53.8125 46.5625 53.8125Z"
        fill="url(#paint9_linear_703_773)"
      />
      <path
        d="M46.2361 41.8262C46.2361 41.3083 45.8165 40.8887 45.2986 40.8887H40.0486C39.5307 40.8887 39.1111 41.3083 39.1111 41.8262C39.1111 42.344 39.5307 42.7637 40.0486 42.7637H45.2986C45.8165 42.7637 46.2361 42.344 46.2361 41.8262Z"
        fill="url(#paint10_linear_703_773)"
      />
      <path
        d="M45.75 49.4375H49.6875C50.2054 49.4375 50.625 49.0179 50.625 48.5C50.625 47.9821 50.2054 47.5625 49.6875 47.5625H45.75C45.2321 47.5625 44.8125 47.9821 44.8125 48.5C44.8125 49.0179 45.2321 49.4375 45.75 49.4375Z"
        fill="url(#paint11_linear_703_773)"
      />
      <path
        d="M40.5625 47.5625C40.0446 47.5625 39.625 47.9821 39.625 48.5C39.625 49.0179 40.0446 49.4375 40.5625 49.4375H41.75C42.2679 49.4375 42.6875 49.0179 42.6875 48.5C42.6875 47.9821 42.2679 47.5625 41.75 47.5625H40.5625Z"
        fill="url(#paint12_linear_703_773)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_703_773"
          x1="51.0625"
          y1="-0.945398"
          x2="63.1393"
          y2="0.370279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_773"
          x1="48.3254"
          y1="9.44188"
          x2="66.0896"
          y2="12.0068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_703_773"
          x1="51.0625"
          y1="32.0546"
          x2="63.1393"
          y2="33.3703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_703_773"
          x1="48.3254"
          y1="42.4419"
          x2="66.0896"
          y2="45.0068"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_703_773"
          x1="-3.09375"
          y1="-18.2462"
          x2="43.1002"
          y2="-14.3025"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_703_773"
          x1="38.1562"
          y1="7.26136"
          x2="46.7965"
          y2="11.5913"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_703_773"
          x1="38.5312"
          y1="19.7614"
          x2="46.1548"
          y2="22.9174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_703_773"
          x1="45.6823"
          y1="13.5114"
          x2="51.0252"
          y2="14.9277"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_703_773"
          x1="39.2656"
          y1="13.5114"
          x2="44.3514"
          y2="14.7857"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_703_773"
          x1="38.1562"
          y1="52.7614"
          x2="46.7965"
          y2="57.0913"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_703_773"
          x1="38.5173"
          y1="39.8375"
          x2="46.1409"
          y2="42.9936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_703_773"
          x1="44.3281"
          y1="46.5114"
          x2="50.8674"
          y2="48.7198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_703_773"
          x1="39.3698"
          y1="46.5114"
          x2="43.0904"
          y2="47.1734"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#150343" />
          <stop offset="0.505208" stopColor="#B3304A" />
          <stop offset="1" stopColor="#EF4F34" />
        </linearGradient>
      </defs>
    </svg>
  );
};
