export const LineMediumShadow = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 1920 582"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 0H1920V582H0V0Z" fill="url(#paint0_linear_703_56)" />
      <defs>
        <linearGradient
          id="paint0_linear_703_56"
          x1="960"
          y1="-7.05626"
          x2="960"
          y2="574.944"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#050913" stopOpacity="0" />
          <stop offset="0.480557" stopColor="#050913" />
          <stop offset="1" stopColor="#050913" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
