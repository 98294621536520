export const SignalIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 55 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M41.58 75.6001H13.27C7.6 75.6001 3 70.9901 3 65.3301V64.0401C3 50.5701 13.96 39.6101 27.43 39.6101C40.9 39.6101 51.86 50.5701 51.86 64.0401V65.3301C51.85 70.9901 47.24 75.6001 41.58 75.6001ZM27.42 40.6701C14.53 40.6701 4.04 51.1601 4.04 64.0501V65.3401C4.04 70.4201 8.18 74.5601 13.26 74.5601H41.57C46.65 74.5601 50.79 70.4201 50.79 65.3401V64.0501C50.8 51.1501 40.31 40.6701 27.42 40.6701Z"
        fill="url(#paint0_linear_703_910)"
      />
      <path
        d="M38.58 71.8299H16.26C11.74 71.8299 8.04999 68.2699 8.04999 63.8799V62.8999C8.04999 52.5699 16.74 44.1699 27.41 44.1699C38.09 44.1699 46.78 52.5699 46.78 62.8999V63.8799C46.79 68.2699 43.11 71.8299 38.58 71.8299ZM27.42 45.2299C17.32 45.2299 9.09999 53.1599 9.09999 62.9099V63.8899C9.09999 67.6899 12.31 70.7899 16.26 70.7899H38.58C42.53 70.7899 45.74 67.6999 45.74 63.8899V62.8999C45.74 53.1599 37.52 45.2299 27.42 45.2299Z"
        fill="url(#paint1_linear_703_910)"
      />
      <path
        d="M20.23 58.37C18.12 58.37 16.4 56.65 16.4 54.54C16.4 52.43 18.12 50.71 20.23 50.71C22.34 50.71 24.06 52.43 24.06 54.54C24.05 56.65 22.34 58.37 20.23 58.37ZM20.23 51.76C18.7 51.76 17.45 53.01 17.45 54.54C17.45 56.07 18.7 57.32 20.23 57.32C21.76 57.32 23.01 56.07 23.01 54.54C23.01 53.01 21.76 51.76 20.23 51.76Z"
        fill="url(#paint2_linear_703_910)"
      />
      <path
        d="M34.62 58.37C32.51 58.37 30.79 56.65 30.79 54.54C30.79 52.43 32.51 50.71 34.62 50.71C36.73 50.71 38.45 52.43 38.45 54.54C38.44 56.65 36.73 58.37 34.62 58.37ZM34.62 51.76C33.09 51.76 31.84 53.01 31.84 54.54C31.84 56.07 33.09 57.32 34.62 57.32C36.15 57.32 37.4 56.07 37.4 54.54C37.4 53.01 36.15 51.76 34.62 51.76Z"
        fill="url(#paint3_linear_703_910)"
      />
      <path
        d="M27.42 67.8101C25.13 67.8101 23.48 66.9401 22.52 65.2101C22.38 64.9601 22.47 64.6401 22.72 64.5001C22.97 64.3601 23.29 64.4501 23.43 64.7001C24.2 66.0901 25.5 66.7601 27.41 66.7601C29.32 66.7601 30.62 66.0901 31.39 64.7001C31.53 64.4501 31.85 64.3601 32.1 64.5001C32.35 64.6401 32.44 64.9601 32.3 65.2101C31.36 66.9301 29.71 67.8101 27.42 67.8101Z"
        fill="url(#paint4_linear_703_910)"
      />
      <path
        d="M3.53 64.05C1.59 64.05 0 62.47 0 60.52V57.96C0 53.97 3.25 50.72 7.24 50.72C7.43 50.72 7.61 50.82 7.7 50.99C7.79 51.16 7.79 51.36 7.68 51.52C5.4 55.11 4.14 59.27 4.05 63.54C4.05 63.82 3.82 64.05 3.53 64.05ZM6.27 51.84C3.32 52.31 1.05 54.88 1.05 57.96V60.52C1.05 61.72 1.9 62.72 3.03 62.95C3.2 59.03 4.31 55.22 6.27 51.84Z"
        fill="url(#paint5_linear_703_910)"
      />
      <path
        d="M51.31 64.05C51.02 64.05 50.79 63.82 50.79 63.54C50.7 59.27 49.44 55.11 47.16 51.52C47.06 51.36 47.05 51.15 47.14 50.99C47.23 50.82 47.41 50.72 47.6 50.72C51.59 50.72 54.84 53.97 54.84 57.96V60.52C54.84 62.47 53.26 64.05 51.31 64.05ZM48.58 51.84C50.53 55.22 51.64 59.03 51.82 62.95C52.95 62.72 53.8 61.71 53.8 60.52V57.96C53.8 54.88 51.53 52.31 48.58 51.84Z"
        fill="url(#paint6_linear_703_910)"
      />
      <path
        d="M27.42 40.6701C27.13 40.6701 26.9 40.4401 26.9 40.1501V32.8501C26.9 32.5601 27.13 32.3301 27.42 32.3301C27.71 32.3301 27.94 32.5601 27.94 32.8501V40.1501C27.95 40.4301 27.71 40.6701 27.42 40.6701Z"
        fill="url(#paint7_linear_703_910)"
      />
      <path
        d="M27.42 33.3701C25.69 33.3701 24.28 31.9601 24.28 30.2301C24.28 28.5001 25.69 27.0901 27.42 27.0901C29.15 27.0901 30.56 28.5001 30.56 30.2301C30.56 31.9601 29.15 33.3701 27.42 33.3701ZM27.42 28.1401C26.27 28.1401 25.33 29.0801 25.33 30.2301C25.33 31.3801 26.27 32.3201 27.42 32.3201C28.57 32.3201 29.51 31.3801 29.51 30.2301C29.51 29.0801 28.57 28.1401 27.42 28.1401Z"
        fill="url(#paint8_linear_703_910)"
      />
      <path
        d="M36.45 21.7299C36.32 21.7299 36.18 21.68 36.08 21.58C33.77 19.27 30.69 17.99 27.42 17.99C24.15 17.99 21.07 19.26 18.76 21.58C18.56 21.78 18.22 21.78 18.02 21.58C17.82 21.38 17.82 21.04 18.02 20.84C20.53 18.33 23.87 16.95 27.42 16.95C30.97 16.95 34.31 18.33 36.82 20.84C37.02 21.04 37.02 21.38 36.82 21.58C36.72 21.68 36.59 21.7299 36.45 21.7299Z"
        fill="url(#paint9_linear_703_910)"
      />
      <path
        d="M26.37 9.92009C26.09 9.92009 25.86 9.70009 25.85 9.42009C25.84 9.13009 26.06 8.88009 26.35 8.87009C26.71 8.85009 27.06 8.84009 27.42 8.84009C27.71 8.84009 27.94 9.07009 27.94 9.36009C27.94 9.65009 27.71 9.90009 27.42 9.90009C27.08 9.90009 26.74 9.91009 26.4 9.93009C26.39 9.92009 26.38 9.92009 26.37 9.92009Z"
        fill="url(#paint10_linear_703_910)"
      />
      <path
        d="M15.01 13.99C14.85 13.99 14.69 13.92 14.59 13.78C14.42 13.55 14.47 13.22 14.7 13.05C15.26 12.64 15.84 12.24 16.44 11.89C16.69 11.74 17.01 11.82 17.16 12.07C17.31 12.32 17.23 12.64 16.98 12.79C16.41 13.13 15.86 13.5 15.33 13.89C15.23 13.96 15.12 13.99 15.01 13.99ZM18.51 11.89C18.31 11.89 18.13 11.78 18.04 11.59C17.92 11.33 18.03 11.02 18.29 10.89C18.92 10.59 19.57 10.32 20.22 10.09C20.49 9.99004 20.79 10.13 20.89 10.41C20.99 10.68 20.85 10.98 20.57 11.08C19.95 11.3 19.33 11.56 18.73 11.84C18.66 11.88 18.58 11.89 18.51 11.89ZM22.34 10.52C22.11 10.52 21.89 10.36 21.83 10.12C21.76 9.84004 21.93 9.55004 22.21 9.48004C22.88 9.31004 23.57 9.17004 24.26 9.07004C24.55 9.03004 24.81 9.23004 24.86 9.51004C24.9 9.80004 24.7 10.06 24.42 10.11C23.76 10.21 23.11 10.34 22.47 10.5C22.43 10.52 22.38 10.52 22.34 10.52Z"
        fill="url(#paint11_linear_703_910)"
      />
      <path
        d="M12.67 16.0099C12.54 16.0099 12.4 15.9599 12.3 15.8599C12.1 15.6599 12.1 15.3199 12.3 15.1199C12.55 14.8699 12.81 14.6199 13.08 14.3799C13.29 14.1899 13.63 14.1999 13.82 14.4199C14.01 14.6299 14 14.9699 13.78 15.1599C13.53 15.3899 13.28 15.6199 13.04 15.8599C12.94 15.9599 12.81 16.0099 12.67 16.0099Z"
        fill="url(#paint12_linear_703_910)"
      />
      <path
        d="M42.17 16.0101C42.04 16.0101 41.9 15.9601 41.8 15.8601C37.96 12.0201 32.85 9.90011 27.42 9.90011C27.13 9.90011 26.9 9.67011 26.9 9.38011C26.9 9.09011 27.13 8.86011 27.42 8.86011C33.13 8.86011 38.5 11.0801 42.54 15.1201C42.74 15.3201 42.74 15.6601 42.54 15.8601C42.44 15.9601 42.31 16.0101 42.17 16.0101Z"
        fill="url(#paint13_linear_703_910)"
      />
      <path
        d="M6.41001 9.75C6.28001 9.75 6.14001 9.7 6.04001 9.6C5.84001 9.4 5.84001 9.06 6.04001 8.86C11.75 3.14 19.35 0 27.42 0C35.5 0 43.09 3.14 48.8 8.86C49 9.06 49 9.4 48.8 9.6C48.6 9.8 48.26 9.8 48.06 9.6C42.55 4.09 35.22 1.05 27.42 1.05C19.62 1.05 12.3 4.09 6.78001 9.6C6.68001 9.7 6.55001 9.75 6.41001 9.75Z"
        fill="url(#paint14_linear_703_910)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_703_910"
          x1="27.43"
          y1="39.6101"
          x2="27.43"
          y2="75.6001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_910"
          x1="27.415"
          y1="44.1699"
          x2="27.415"
          y2="71.8299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_703_910"
          x1="20.23"
          y1="50.71"
          x2="20.23"
          y2="58.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_703_910"
          x1="34.62"
          y1="50.71"
          x2="34.62"
          y2="58.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_703_910"
          x1="27.41"
          y1="64.4341"
          x2="27.41"
          y2="67.8101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_703_910"
          x1="3.88259"
          y1="50.72"
          x2="3.88259"
          y2="64.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_703_910"
          x1="50.9592"
          y1="50.72"
          x2="50.9592"
          y2="64.05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_703_910"
          x1="27.4201"
          y1="32.3301"
          x2="27.4201"
          y2="40.6701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_703_910"
          x1="27.42"
          y1="27.0901"
          x2="27.42"
          y2="33.3701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_703_910"
          x1="27.42"
          y1="16.95"
          x2="27.42"
          y2="21.73"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_703_910"
          x1="26.8948"
          y1="8.84009"
          x2="26.8948"
          y2="9.93009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_703_910"
          x1="19.6771"
          y1="9.06494"
          x2="19.6771"
          y2="13.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_703_910"
          x1="13.0522"
          y1="14.2456"
          x2="13.0522"
          y2="16.0099"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_703_910"
          x1="34.795"
          y1="8.86011"
          x2="34.795"
          y2="16.0101"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_703_910"
          x1="27.42"
          y1="0"
          x2="27.42"
          y2="9.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
      </defs>
    </svg>
  );
};
