export const BellIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 44 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.562 37.7258C42.2745 37.0478 41.8633 36.4389 41.3395 35.9162C40.8157 35.3935 40.2057 34.9832 39.5261 34.6964C39.2935 34.5982 39.0564 34.5161 38.8151 34.4502V34.9985H37.4612V34.2509C37.4093 34.2495 37.3572 34.2488 37.305 34.2488H5.70535C5.65313 34.2488 5.60106 34.2495 5.54914 34.2509V34.9985H4.19529V34.4502C3.95413 34.5163 3.71683 34.5982 3.4843 34.6964C2.80484 34.9832 2.19472 35.3935 1.67088 35.9162C1.14705 36.4389 0.735838 37.0476 0.448406 37.7258C0.151006 38.4281 0 39.1738 0 39.9421C0 40.7103 0.151006 41.456 0.448704 42.1584C0.736136 42.8364 1.14735 43.4452 1.67118 43.9679C2.19502 44.4906 2.80499 44.9009 3.48459 45.1877C4.18845 45.4848 4.93574 45.6355 5.70565 45.6355H16.3082C16.2771 45.4082 16.2612 45.1779 16.2612 44.9452H17.615C17.615 45.1811 17.6375 45.4118 17.6799 45.6355H24.9739C25.0163 45.4118 25.0387 45.1811 25.0387 44.9452H26.3926C26.3926 45.1779 26.3767 45.4082 26.3456 45.6355H37.3052C38.0751 45.6355 38.8224 45.4848 39.5262 45.1877C40.2057 44.9009 40.8158 44.4906 41.3396 43.9679C41.8635 43.4452 42.2747 42.8365 42.5621 42.1584C42.8598 41.456 43.0108 40.7103 43.0108 39.9421C43.0108 39.1738 42.8597 38.4281 42.562 37.7258ZM37.3052 44.2844H5.7055C3.30592 44.2844 1.35385 42.3365 1.35385 39.9421C1.35385 37.5476 3.30592 35.5997 5.7055 35.5997H37.3052C39.7048 35.5997 41.6568 37.5476 41.6568 39.9421C41.6568 42.3365 39.7048 44.2844 37.3052 44.2844Z"
        fill="url(#paint0_linear_703_934)"
      />
      <path
        d="M21.3269 48.6489C19.5164 48.6489 18.0049 47.3489 17.6798 45.6353H16.3081C16.3681 46.0742 16.4853 46.5019 16.6595 46.9127C16.9147 47.5147 17.2799 48.0552 17.7448 48.5193C18.2099 48.9834 18.7516 49.3477 19.3549 49.6023C19.9799 49.8661 20.6432 49.9999 21.3269 49.9999C22.0105 49.9999 22.6739 49.8661 23.2989 49.6023C23.9021 49.3477 24.4438 48.9832 24.9089 48.5193C25.374 48.0552 25.7391 47.5147 25.9942 46.9127C26.1684 46.5018 26.2855 46.0742 26.3456 45.6353H24.9739C24.6488 47.3489 23.1373 48.6489 21.3269 48.6489Z"
        fill="url(#paint1_linear_703_934)"
      />
      <path
        d="M25.0386 44.9448C25.0386 45.1807 25.0162 45.4114 24.9738 45.6351H26.3455C26.3765 45.4079 26.3925 45.1776 26.3925 44.9448H25.0386Z"
        fill="url(#paint2_linear_703_934)"
      />
      <path
        d="M17.6148 44.9448H16.261C16.261 45.1776 16.2769 45.4079 16.308 45.6351H17.6797C17.6373 45.4114 17.6148 45.1807 17.6148 44.9448Z"
        fill="url(#paint3_linear_703_934)"
      />
      <path
        d="M21.5053 1.35095C23.4754 1.35095 25.0916 2.89043 25.2099 4.82719C25.6674 4.93942 26.1213 5.07288 26.571 5.22698V5.05492C26.571 4.37277 26.437 3.7108 26.1726 3.08714C25.9175 2.48515 25.5522 1.94462 25.0873 1.48055C24.6223 1.01648 24.0806 0.652166 23.4773 0.397565C22.8523 0.133759 22.1889 0 21.5053 0C20.8217 0 20.1583 0.133759 19.5333 0.397565C18.93 0.652166 18.3883 1.01663 17.9232 1.48055C17.4582 1.94462 17.0931 2.48515 16.8379 3.08714C16.5736 3.7108 16.4395 4.37277 16.4395 5.05492V5.22698C16.8893 5.07273 17.343 4.93942 17.8007 4.82719C17.9189 2.89043 19.5352 1.35095 21.5053 1.35095Z"
        fill="url(#paint4_linear_703_934)"
      />
      <path
        d="M5.54928 22.736C5.54928 13.6769 13.0054 5.73034 21.5053 5.73034C23.7187 5.73034 25.861 6.26924 27.8195 7.22618C28.0245 6.81169 28.2683 6.41992 28.5494 6.05398C28.4564 6.00781 28.3631 5.96238 28.2695 5.91814C27.7109 5.65389 27.1443 5.42363 26.571 5.22693V5.71787H25.2171V5.05487C25.2171 4.97841 25.2144 4.90255 25.2098 4.82714C23.9973 4.52948 22.7591 4.37939 21.5052 4.37939C20.2512 4.37939 19.0131 4.52963 17.8006 4.82714C17.7959 4.90255 17.7933 4.97841 17.7933 5.05487V5.71787H16.4394V5.22693C15.866 5.42348 15.2995 5.65374 14.7409 5.91814C13.7186 6.40181 12.7291 6.99845 11.8002 7.69129C10.8889 8.37107 10.0292 9.14824 9.24499 10.0014C8.46704 10.8476 7.75888 11.7735 7.14013 12.7532C6.51899 13.7367 5.98445 14.7799 5.55122 15.8541C5.11144 16.9448 4.77327 18.0747 4.5461 19.2127C4.31356 20.3792 4.19543 21.5648 4.19543 22.736V34.4502C4.63491 34.3299 5.08778 34.2631 5.54928 34.2508V22.736Z"
        fill="url(#paint5_linear_703_934)"
      />
      <path
        d="M5.54928 34.9984V34.2507C5.08778 34.2631 4.63491 34.3299 4.19543 34.4501V34.9984H5.54928Z"
        fill="url(#paint6_linear_703_934)"
      />
      <path
        d="M25.2173 5.05488V5.71788H26.5712V5.22694C26.1214 5.07269 25.6677 4.93938 25.21 4.82715C25.2146 4.90256 25.2173 4.97842 25.2173 5.05488Z"
        fill="url(#paint7_linear_703_934)"
      />
      <path
        d="M17.7934 5.71788V5.05488C17.7934 4.97842 17.796 4.90256 17.8007 4.82715C17.3432 4.93938 16.8893 5.07284 16.4395 5.22694V5.71788H17.7934Z"
        fill="url(#paint8_linear_703_934)"
      />
      <path
        d="M36.4943 17.1253C37.1177 18.8992 37.4614 20.7919 37.4614 22.7359V34.2507C37.9229 34.263 38.3757 34.3298 38.8152 34.4501V22.7359C38.8152 21.5647 38.6971 20.3792 38.4642 19.2124C38.2868 18.3229 38.041 17.4384 37.7321 16.5735C37.4945 16.7077 37.2488 16.8287 36.9951 16.9357C36.83 17.0053 36.663 17.0686 36.4943 17.1253Z"
        fill="url(#paint9_linear_703_934)"
      />
      <path
        d="M37.4614 34.9984H38.8153V34.4501C38.3758 34.3299 37.9229 34.2631 37.4614 34.2507V34.9984Z"
        fill="url(#paint10_linear_703_934)"
      />
      <path
        d="M28.4213 7.5366C28.2229 7.42882 28.0222 7.32534 27.8197 7.22632C27.7581 7.35088 27.6999 7.47751 27.6455 7.60607C27.2732 8.48404 27.0846 9.41634 27.0846 10.3771C27.0846 11.338 27.2732 12.2701 27.6455 13.1482C28.0048 13.9959 28.5191 14.7572 29.174 15.4107C29.8289 16.0642 30.5918 16.5774 31.4413 16.9359C32.3212 17.3074 33.2556 17.4956 34.2183 17.4956C35.0004 17.4956 35.7638 17.3713 36.4946 17.1255C36.4197 16.9126 36.3412 16.7012 36.2583 16.4917C36.6913 16.348 37.1038 16.1598 37.4906 15.9325C37.5751 16.1448 37.6556 16.3587 37.7324 16.5737C38.2907 16.2584 38.8033 15.8691 39.2627 15.4105C39.9176 14.757 40.4319 13.9958 40.7912 13.1481C41.1634 12.2701 41.3521 11.3378 41.3521 10.377C41.3521 9.41619 41.1634 8.48404 40.7912 7.60593C40.4319 6.75824 39.9176 5.99696 39.2627 5.34346C38.6078 4.68996 37.8449 4.17674 36.9953 3.81822C36.1155 3.44679 35.181 3.25854 34.2183 3.25854C33.2556 3.25854 32.3213 3.44679 31.4413 3.81822C30.5918 4.17674 29.8289 4.68996 29.174 5.34346C28.949 5.56792 28.7408 5.80501 28.5496 6.05397C28.7544 6.15581 28.958 6.26195 29.1597 6.37255C28.8741 6.73152 28.6259 7.12151 28.4213 7.5366ZM34.2182 4.60964C37.4052 4.60964 39.9981 7.19693 39.9981 10.3771C39.9981 13.5574 37.4052 16.1447 34.2182 16.1447C31.0311 16.1447 28.4383 13.5574 28.4383 10.3771C28.4383 7.19693 31.0311 4.60964 34.2182 4.60964Z"
        fill="url(#paint11_linear_703_934)"
      />
      <path
        d="M28.4213 7.53643C28.626 7.12135 28.874 6.73136 29.1598 6.37254C28.9579 6.26194 28.7545 6.15565 28.5496 6.05396C28.2686 6.42005 28.0248 6.81167 27.8198 7.22616C28.0221 7.32518 28.2226 7.42866 28.4213 7.53643Z"
        fill="url(#paint12_linear_703_934)"
      />
      <path
        d="M36.2581 16.4919C36.3408 16.7013 36.4195 16.9126 36.4943 17.1256C36.663 17.0689 36.8301 17.0057 36.9951 16.936C37.2487 16.829 37.4945 16.708 37.7321 16.5738C37.6553 16.3588 37.5749 16.1449 37.4904 15.9326C37.1035 16.1599 36.691 16.348 36.2581 16.4919Z"
        fill="url(#paint13_linear_703_934)"
      />
      <path
        d="M32.3279 9.7668C32.5517 9.7668 32.7799 9.63097 32.9671 9.52363C33.2136 9.3823 33.4454 9.21945 33.6741 9.05214C33.7957 8.96306 33.967 9.05154 33.9639 9.20208C33.9509 9.83628 33.9747 10.4755 33.9747 11.1081C33.9747 11.8063 33.9747 12.5045 33.9747 13.2027C33.9747 13.459 34.0341 13.6528 34.153 13.7842C34.2717 13.9155 34.4291 13.9812 34.6249 13.9812C35.0646 13.9812 35.2845 13.6592 35.2845 13.0153V7.51325C35.2845 7.28255 35.2332 7.10158 35.1304 6.97019C35.0276 6.83881 34.8912 6.77319 34.7211 6.77319C34.5701 6.77319 34.4675 6.80199 34.4129 6.85974C33.8771 7.4261 33.3947 8.0802 32.6926 8.45505C32.368 8.62845 31.8124 8.77942 31.8124 9.23355C31.8124 9.37458 31.8647 9.49869 31.9689 9.60603C32.0734 9.71321 32.193 9.7668 32.3279 9.7668Z"
        fill="url(#paint14_linear_703_934)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_703_934"
          x1="21.5054"
          y1="34.2488"
          x2="21.5054"
          y2="45.6355"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_703_934"
          x1="21.3269"
          y1="45.6353"
          x2="21.3269"
          y2="49.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_703_934"
          x1="25.6831"
          y1="44.9448"
          x2="25.6831"
          y2="45.6351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_703_934"
          x1="16.9703"
          y1="44.9448"
          x2="16.9703"
          y2="45.6351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_703_934"
          x1="21.5053"
          y1="0"
          x2="21.5053"
          y2="5.22698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_703_934"
          x1="16.3724"
          y1="4.37939"
          x2="16.3724"
          y2="34.4502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_703_934"
          x1="4.87236"
          y1="34.2507"
          x2="4.87236"
          y2="34.9984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_703_934"
          x1="25.8906"
          y1="4.82715"
          x2="25.8906"
          y2="5.71788"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_703_934"
          x1="17.1201"
          y1="4.82715"
          x2="17.1201"
          y2="5.71788"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_703_934"
          x1="37.6548"
          y1="16.5735"
          x2="37.6548"
          y2="34.4501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_703_934"
          x1="38.1383"
          y1="34.2507"
          x2="38.1383"
          y2="34.9984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_703_934"
          x1="34.2183"
          y1="3.25854"
          x2="34.2183"
          y2="17.4956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_703_934"
          x1="28.4898"
          y1="6.05396"
          x2="28.4898"
          y2="7.53643"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_703_934"
          x1="36.9951"
          y1="15.9326"
          x2="36.9951"
          y2="17.1256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_703_934"
          x1="33.5485"
          y1="6.77319"
          x2="33.5485"
          y2="13.9812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4F34" />
          <stop offset="1" stopColor="#411045" />
        </linearGradient>
      </defs>
    </svg>
  );
};
